import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import str5 from '../assets/Home_Strip4.png';
import str4 from '../assets/Home_Strip5.png';
import bgTop from '../assets/OurPrinciples.jpg';
import Task from '../assets/Professional.png';
import Lady from '../assets/Redefining.jpeg';
import Safe from '../assets/Safety.png';
import Trans from '../assets/financial-profit.png';
import HomeTop from '../assets/group.jpg';
import ResI from '../assets/idea.png';
import Join from '../assets/jo.png';
import mobile from '../assets/mobile.png';
import GEn from '../assets/ourj.jpeg';
import Relation from '../assets/relationship.png';
import Fair from '../assets/service.png';


function AboutUs() {
    const card = [
        {
            img: Safe,
            head: 'SAFETY FIRST',
            p1: 'Our foremost commitment is ensuring the safety of both our riders and drivers.',

        },
        {
            img: Fair,
            head: 'QUALITY SERVICE',
            p1: 'We strive for excellence in every ride, ensuring timely, comfortable, and reliable travel for our riders.',

        }, {
            img: ResI,
            head: 'INNOVATION',
            p1: 'Adapting and evolving is in our DNA. Our technological advancements are tailored to cater to the modern Joburg resident.',

        }, {
            img: Trans,
            head: 'COST-EFFECTIVE RIDES',
            p1: "Fair pricing is at our core. With our fixed prices, riders no longer need to anticipate variable charges",

        }, {
            img: Task,
            head: 'PROFESSIONAL CONDUCT',
            p1: "From our drivers to our support staff, professional conduct is a standard, not an exception.",

        }, {
            img: Relation,
            head: 'NURTURING RELATIONSHIPS',
            p1: "Driver & client fingerprint identification ensures peace of mind with every ride.",

        }
    ]
    return (
        <div>
            <div className="lg:min-h-[90vh] min-h-[50vh] bg-cover  bg-no-repeat pt-5 " style={{ backgroundImage: `url(${HomeTop})`, boxShadow: 'rgba(0, 0, 0, 0.6) 500px 2000px inset', }} >
                <Slide direction='down' triggerOnce>
                    <div className="text-center pt-10">
                        <h1 className='text-3xl md:text-6xl lg:text-6xl font-bold text-white'>Ride With Respect:
                            <br />
                            <Fade delay={1000} triggerOnce duration={1000} cascade>
                                <div className='text-white font-bold lg:pt-3 text-2xl md:text-6xl lg:text-7xl '>

                                    Where  <span className='text-[#f62f02]'>Everyone</span>    Matters
                                </div>
                            </Fade>

                        </h1>
                    </div>
                </Slide>
            </div>

            <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 px-5  md:px-5 lg:px-20 gap-5  items-start mt-5'>
                <Slide direction='left' triggerOnce>
                    <div className=" lg:px-5 md:text-start flex justify-start">
                        <img src={Lady} alt="" />
                    </div>
                </Slide>
                <Slide direction='right' className='mt-5 lg:mt-0' triggerOnce>
                    <div className="lg:p-2 flex justify-start flex-col">
                        <h3 className='text-black text-lg md:text-2xl lg:text-4xl font-extrabold'>
                            REDEFINING E-HAILING
                            <br />
                            <span className='text-[#f62f02]'> IN SOUTH AFRICA</span>
                        </h3>
                        <div className=" p-3 my-2 md:my-5 lg:my-8 text-lg  space-y-3 lg:space-y-8  lg:text-2xl ">
                            <p className='' >
                                At Shesha, we don't just offer rides; we craft personalized travel experiences.
                            </p>
                            <p>
                                Our commitment to safety, top-notch service, and budget-friendly rides transforms every journey into a pleasure.
                            </p>
                            <p>Whether you're behind the wheel <br />
                                or in the passenger seat, join the Ride Revolution and choose Shesha for reliable and safe transportation.</p>
                        </div>

                    </div>
                </Slide>
            </section>
            <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 px-5 md:px-12 mt-16 lg:px-20 gap-5 items-start '>

                <Slide direction='left' className='' triggerOnce>
                    <div className="lg:p-2 flex justify-start flex-col">
                        <h3 className='text-[#f62f02] text-lg md:text-2xl lg:text-4xl font-extrabold'>
                            OUR
                            <span className='text-black '> JOURNEY</span>
                        </h3>
                        <div className=" p-3 my-2 md:my-5 lg:my-8 text-lg  space-y-4 lg:space-y-8  lg:text-2xl ">
                            <p className='' >
                                Before the dawn of Shesha, we observed, we listened, and we understood.
                            </p>
                            <p>
                                The people of SA yearned for a dependable, fair-priced, and quality e-hailing service that prioritizes them.
                            </p>
                            <p>We took this challenge head-on, and Shesha was the result.</p>
                        </div>

                    </div>
                </Slide>
                <Slide direction='right' triggerOnce>
                    <div className="text-center">
                        <img src={GEn} alt="" />
                    </div>
                </Slide>
            </section>
            <section className='lg:h-[130vh] bg-cover bg-no-repeat' style={{ backgroundImage: `url(${str5})`, backgroundSize: '80%', backgroundPositionY: '80%' }}>
                <section className="lg:min-h-[80vh] min-h-[40vh] pt-5 bg-cover flex flex-col justify-center  items-center bg-center relative" style={{ backgroundImage: `url(${bgTop})`, boxShadow: 'rgba(0, 0, 0, 0.6) 500px 2000px inset', }}  >
                    <h3 className='text-[#f62f02] text-lg md:text-3xl lg:text-4xl font-extrabold uppercase'>
                        Our
                        <span className='text-white '> Principles</span>
                    </h3>

                </section>

            </section>

            <section className=" min-h-[50vh] pt-5 bg-cover bg-no-repeat     flex flex-col justify-center  items-center   relative "  >

                <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-2 place-items-center  place-content-center px-5 gap-5 lg:gap-32 mt-2 lg:mt-24 lg:absolute lg:-top-[680px]">
                    {card.map((e, i) => {
                        return (
                            <Fade delay={i * 150} direction='up' triggerOnce>
                                <div className="card relative max-w-[18rem] border-2 lg:p-5 md:p-3 p-2 my-5 lg:my-0 min-h-[200px] lg:min-h-[250px]   rounded-lg bg-white shadow-2xl">
                                    <div className="absolute-div ">
                                        <img src={e.img} alt="" className='p-2 lg:p-4 text-center h-[40px] md:h-[60px] lg:h-[80px] bg-[#f62f02] rounded-full object-fill ' />
                                    </div>
                                    <div className="text-center pt-8  ">
                                        <h1 className='pb-2 font-[900] text-sm lg:text-md'>{e.head}</h1>
                                        <p className='font-medium text-xs md:text-sm lg:text-lg'>{e.p1}</p>

                                    </div>

                                </div>
                            </Fade>
                        )
                    })}


                </div>


                <div className="lg:px-52 px-5  mt-2 bg-white lg:bg-none">
                    <h3 className='text-[#f62f02] text-lg py-8 md:text-3xl lg:text-4xl font-extrabold uppercase'>
                        OUR PROMISE
                        <span className='text-black '> TO SOUTH AFRICA</span>
                    </h3>
                    <div className="space-y-10 text-lg lg:text-xl">
                        <p>At Shesha, we believe in a balanced approach. While many platforms prioritize only the rider, we see the bigger picture. Our drivers are the backbone of our service. By ensuring they are satisfied, respected, and treated fairly, we create a better experience for our riders. It's a cycle of respect and value, turning every trip into a journey of mutual appreciation.</p>
                        <p>
                            We might be competing with international giants, but our heart beats with the rhythm of South Africa. We promise to provide a service that respects the hustle and bustle of our riders, understands their needs, and continually evolves to serve them better.
                        </p>
                    </div>
                </div>

            </section>

            <section className="lg:min-h-[90vh] min-h-[50vh] pt-5  bg-cover  bg-left flex flex-col justify-center  items-center   relative -z-10" style={{ backgroundImage: `url(${str4})`, backgroundSize: 'cover', backgroundPosition: 'left center' }}>
                <img src={Join} alt="" className='max-h-[800px]' />
            </section>
            <section className='flex justify-center items-center flex-col ld:my-10'>
                <div className="text-center">

                    <h3 className='text-[#f62f02] text-lg py-8 md:text-3xl lg:text-5xl font-extrabold uppercase '>
                        join
                        <span className='text-black '> the</span> shesha family
                    </h3>
                    <p className=' lg:text-2xl md:text-lg text-sm'> Whether You're Looking To Commute, explore or Earn Shesha Is Here For
                        <br />
                        You. Let's Ride  Further Together
                    </p>
                </div>
                <img src={mobile} alt="" className='max-h-[800px]' />


            </section>
        </div>
    )
}

export default AboutUs