import React from 'react'

function Privacy() {
    return (
        <div className='my-10'>
            <div className=" px-3 lg:px-10">
                <div className="whole-div p-5">
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span >
                                <strong>PRIVACY POLICY</strong>
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                At Shesha Ehailing Services (Pty) Ltd (2023/577025/07) (“
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                <strong>Shesha</strong>
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                ”) we adhere to the highest standards of protecting your personal
                                information when we process it by virtue of your use of our
                                Services, our Platform{" "}
                            </span>
                        </span>
                        <span >
                            <a
                                style={{ fontSize: "small" }}
                                href="https://backend.shesharide.co.za/"
                                target="_blank"
                            >
                                Shesa-E-hailing
                            </a>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                , our application (collectively, “
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                <strong>the Platform</strong>
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                ”), or by providing us with your personal information in any other
                                way. As such, we have created this privacy policy for you to read
                                and to understand how we safeguard your personal information and
                                respect your privacy (“
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                <strong>Privacy </strong>
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                <strong>Policy</strong>
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>”). </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    Please note that Shesha is a private company duly registered and
                                    operating in accordance with the laws of the Republic of South
                                    Africa.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    Not all terms are necessarily defined in order or may be defined
                                    in our Terms and Conditions of Use (“
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <strong>Terms</strong>
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>”).</span>
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    Please ensure that you read all the provisions below, and our
                                    policies and guidelines which may apply from time to time, to
                                    understand all of your, and our, rights and duties.
                                </span>
                            </span>
                        </span>
                    </p>
                    <ol className='mt-5'>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ fontSize: 16 }}>
                                    <span style={{ color: "#000000" }}>
                                        <span >
                                            <span >
                                                <strong>Important Information and Who We Are</strong>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <u>Purpose of this Privacy Policy</u>
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    This Privacy Policy aims to give you information on how we collect
                                    and process your personal information through any form of your
                                    engagement with us.{" "}
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>This </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>Privacy</span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    {" "}
                                    Policy complies with, and facilitates the obligations required
                                    from, the South African{" "}
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <em>Protection of Personal Information Act, No. 4 of 2013</em>
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}> (“</span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <strong>POPI</strong>
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>”), as amended. </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    It is important that you read this Privacy Policy together with
                                    any other privacy policy or fair processing notice we may provide
                                    on specific occasions when we are collecting or processing
                                    personal information about you, so that you are fully aware of how
                                    and why we are using your personal information. This Privacy
                                    Policy supplements the other notices and is not intended to
                                    override them.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We do not process the data of minors. Do not provide us with any
                                    such personal data, as it will constitute an immediate and
                                    automatic breach of this Privacy Policy and our Terms.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    Due to the nature of our services we process limited special
                                    categories of personal information to verify users on our Platform
                                    to ensure we offer a safe experience through our Platform.{" "}
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <u>Responsible Party and Operator</u>
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    Shesha is the “Responsible Party” and is responsible for your
                                    personal information when we decide its processing operations. In
                                    certain instances we may operate as an “Operator” of personal
                                    information on behalf of a third party.{" "}
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <a name="3znysh7" />{" "}
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We have appointed an information officer at Shesha who is
                                    responsible for overseeing questions in relation to this Privacy
                                    Policy. If you have any questions about this Privacy Policy,
                                    including any requests to exercise your legal rights, please
                                    contact our information officer using the details set out below.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <u>Our Contact Details</u>
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>Our full details are:</span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <strong>Full name</strong>
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    : Shesha ehailing Services (Pty) Ltd
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <strong>Information Officer</strong>
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>: Portia Keleketu</span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <strong>Email address</strong>
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>: </span>
                            </span>
                        </span>
                        <span style={{ color: "#0563c1" }}>
                            <u>
                                <a
                                    href="mailto:info@shesharide.co.za"
                                    style={{ color: "#0563c1", textDecoration: "underline" }}
                                >
                                    <span >
                                        <span style={{ fontSize: "small" }}>info@shesharide.co.za</span>
                                    </span>
                                </a>
                            </u>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span > </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <a name="_Hlk161738133" />{" "}
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <strong>Postal address</strong>
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    : Block D, Infinity Business Park, 4 Pieter Wenning Rd, Fourways,{" "}
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 168, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>Sandton, 2191</span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    You have the right to make a complaint at any time to the South
                                    African regulator’s office (
                                    <a
                                        href="https://inforegulator.org.za/"
                                        style={{ color: "#0563c1", textDecoration: "underline" }}
                                    >
                                        Information Regulator’s Office of South Africa
                                    </a>
                                    ). We would, however, appreciate the chance to deal with your
                                    concerns before you approach any such regulator, so please contact
                                    us in the first instance.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <u>Changes to this Privacy Policy </u>
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    This Privacy Policy was last updated on{" "}
                                </span>
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>19 March 2024 </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    and previous versions are archived and can be provided on request.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    It is important that the personal information we hold about you is
                                    accurate and current. Please keep us informed if your personal
                                    information changes during your relationship with us.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                This Privacy Policy is subject to change without notice and is
                                updated or amended from time to time and will be effective once we
                                upload the amended version to the Platform. Your continued access or
                                use of our Services constitutes your acceptance of this Privacy
                                Policy, as amended. It is your responsibility to read this document
                                periodically to ensure you are aware of any changes.
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <u>Third-Party Links on Platform</u>
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    The Platform may include links to third-party platforms, plug-ins,
                                    and applications. Clicking on those links or enabling those
                                    connections may allow third parties to collect or share
                                    information about you. We do not control these third-party
                                    platforms and are not responsible for their privacy statements or
                                    terms. When you leave our Platform, or engage with such third
                                    parties, we encourage you to read the distinct privacy policy of
                                    every third-party you engage with.
                                </span>
                            </span>
                        </span>
                    </p>
                    <ol start={2} className='mt-5'>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ fontSize: 18 }}>
                                    <span style={{ color: "#000000" }}>
                                        <span >
                                            <span >
                                                <strong>What We Collect About You</strong>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>Personal information</span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    , or personally identifiable information, means any information
                                    about an individual, both natural and juristic entities (i.e.,
                                    people and companies), from which that entity can be identified.
                                    It does not include information where the identity has been
                                    removed (anonymous data).
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We may collect, use, store, and transfer (“
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <strong>process</strong>
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    ”) different kinds of personal information about you, which you
                                    provide to us directly or when you use our services or Platform
                                    which we have grouped together as follows:
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                <strong>Identity Data</strong>
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                {" "}
                                including identity or passport number, full name, or the information
                                about your company such as company name and company registration
                                details.
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                <strong>Contact Data</strong>
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                {" "}
                                including email address, physical/registered addresses, and contact
                                phone numbers;
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                <strong>Verification Data </strong>
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                including information submitted during the application process such
                                as identity or passport number, gender, criminal history, biometric
                                image by way of identity or passport documentation, driving license
                                or user-submitted biometric images such as display pictures.
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                <strong>Account Data </strong>
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                including all the data and information available in your account
                                such as username or login information, password, display picture,
                                transaction history, vehicle information, sex, ratings (including
                                those provided and those received).{" "}
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                <strong>Location Data </strong>
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                including precise and approximate location data from users mobile
                                devices when the Platform is running in the foreground or background
                                if they enable us to do so via their device settings.{" "}
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <strong>Financial Data</strong>
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    {" "}
                                    including bank account details and third-party payment provider
                                    information and payment card details (which we do not process but
                                    is processed by a payment service provider on our instruction).
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                <strong>Transaction Data</strong>
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                {" "}
                                including details about payments to and from you, contracts,
                                contractual terms, contract fees, signups, subscriptions, invoices
                                and other details of products and services you have obtained from
                                us, or provide to us.
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                <strong>Technical Data</strong>
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                {" "}
                                including internet protocol address/es, login data, browser type and
                                version, device type, IP address, time zone setting and location,
                                cookies, browser plug-in types and versions, operating system,
                                software, preferred languages, advertising identifiers, mobile
                                network data and platform and other technology on the devices you
                                use to access the Platform.
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                <strong>Usage Data</strong>
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                {" "}
                                including information about how you use our company, Platform and
                                Services and surveys.{" "}
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                <strong>Marketing and Communications Data</strong>
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                {" "}
                                including your preferences in receiving notices and marketing from
                                us and our third parties and your communication preferences as well
                                as details of which communications were sent to you and how they
                                were sent, communication between users enabled through the Platform.
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                <strong>Support Data </strong>
                            </span>
                        </span>
                        <span >
                            <span style={{ fontSize: "small" }}>
                                including a log of all user complaints or support enquiries
                                including chat and call logs, feedback, ratings and reviews.{" "}
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We also collect, use, and share{" "}
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <strong>Aggregated Data</strong>
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    {" "}
                                    such as statistical or demographic data for any purpose.
                                    Aggregated Data may be derived from your personal information but
                                    is not considered personal information in law as this data does{" "}
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <strong>not</strong>
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    {" "}
                                    directly or indirectly reveal your identity. For example, we may
                                    aggregate your Usage Data to calculate the percentage of users
                                    accessing a specific Platform feature. However, if we combine or
                                    connect Aggregated Data with your personal information so that it
                                    can directly or indirectly identify you, we treat the combined
                                    data as personal information which will be used in accordance with
                                    this Privacy Policy.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    Where we need to collect personal information by law, or under the
                                    terms of a contract we have with you and you fail to provide that
                                    data when requested, we may not be able to perform in terms of the
                                    contract we have or are trying to enter into with you (for
                                    example, to provide you with services or allow you to provide us
                                    with your services). In this case, we may have to cancel
                                    Platform-access or Services you have with us, but we will notify
                                    you if this is the case at the time.
                                </span>
                            </span>
                        </span>
                    </p>
                    <ol start={3} className='mt-5'>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ fontSize: 18 }}>
                                    <span style={{ color: "#000000" }}>
                                        <span >
                                            <span >
                                                <strong>How Is Your Personal Information Collected</strong>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We use different methods to collect personal information from and
                                    about you, including through:
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <strong>Direct interactions</strong>
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    : You may give us your personal information directly by signing up
                                    to our Platform or by corresponding with us through the Platform,
                                    by phone, email or otherwise. This includes personal information
                                    you provide when you:
                                </span>
                            </span>
                        </span>
                    </p>
                    <ul>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>use our Services;</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>use our Platform;</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>contract with us;</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    provide any services to us as a service provider or
                                                    independent contractor on contract with us;
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    request information to be sent to you;
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    give us some feedback.
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ul>
                    <p style={{ marginLeft: 28, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <strong>Automated technologies or interactions</strong>
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>: </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    As you interact with our Platform, we may automatically collect
                                    personal information about your equipment, browsing actions and
                                    patterns. We may collect this personal information by using
                                    cookies, server logs and other similar technologies. We may also
                                    receive personal information about you if you visit other
                                    Platforms employing our cookies.{" "}
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 48, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <strong>Third parties:</strong>
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    {" "}
                                    We may receive personal information about you from various third
                                    parties such as:
                                </span>
                            </span>
                        </span>
                    </p>
                    <ul>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    account holders requesting services on behalf of other
                                                    users;
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    users providing information in relation to a complaint or
                                                    dispute;
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    service providers providing verification services;
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    analytics providers;{" "}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    insurance, vehicle, or financial institutions;{" "}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    payment gateway providers;
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    publicly available sources;{" "}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    law enforcement agencies;{" "}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    marketing platforms; and
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    search information providers.{" "}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ul>
                    <ol start={4} className='mt-5'>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ fontSize: 18 }}>
                                    &nbsp;
                                    <span style={{ color: "#000000" }}>
                                        <span >
                                            <span >
                                                <strong>How We Use Your Personal Information</strong>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We will only use your personal information when the law allows us
                                    to and for legitimate reasons, which you hereby expressly
                                    understand and consent to. Most commonly, we will use your
                                    personal information in the following circumstances:
                                </span>
                            </span>
                        </span>
                    </p>
                    <ul className='mt-5'>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    where we have your{" "}
                                                </span>
                                            </span>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    <strong>express consent</strong>
                                                </span>
                                            </span>
                                            <span >
                                                <span style={{ fontSize: "small" }}> to do so;</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    where we need to consult with you or{" "}
                                                </span>
                                            </span>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    <strong>perform on the Services contract</strong>
                                                </span>
                                            </span>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    {" "}
                                                    we are about to enter into or have entered into with you;
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    where it is necessary for our{" "}
                                                </span>
                                            </span>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    <strong>legitimate business interests</strong>
                                                </span>
                                            </span>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    {" "}
                                                    (or those of a third party) and your interests and
                                                    fundamental rights do not override those interests; and/or
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <a name="1t3h5sf" />{" "}
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    where we need to comply with a{" "}
                                                </span>
                                            </span>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    <strong>legal or regulatory obligation</strong>
                                                </span>
                                            </span>
                                            <span >
                                                <span style={{ fontSize: "small" }}>.</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ul>
                    <ol start={5} className='mt-5'>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ fontSize: 18 }}>
                                    <span style={{ color: "#000000" }}>
                                        <span >
                                            <span >
                                                <strong>
                                                    Purposes For Which We Will Use Your Personal Information
                                                </strong>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We have set out below, in a table format, a description of{" "}
                                </span>
                            </span>
                        </span>
                    </p>
                    <ul>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    the purpose for which we will process your personal
                                                    information,{" "}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    the type of data we process; and
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    the lawful basis of processing.{" "}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ul>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    Note that we may process your personal information for more than
                                    one lawful ground depending on the specific purpose for which we
                                    are using your personal information.{" "}
                                </span>
                            </span>
                        </span>
                    </p>
                    <div className='overflow-x-scroll w-full'>
                        <table
                            border={1}
                            style={{ border: "1.00pt solid #cccccc", width: "100%" }}
                            className='mt-5 w-full table table-auto '

                        >
                            <thead>
                                <tr>
                                    <td style={{ backgroundColor: "#bfbfbf", borderColor: "#cccccc" }}>
                                        <p style={{ marginLeft: 2, marginRight: 3, textAlign: "left" }}>
                                            <span style={{ color: "#000000" }}>
                                                <span >
                                                    <span style={{ fontSize: "small" }}>
                                                        <strong>PURPOSE / ACTIVITY</strong>
                                                    </span>
                                                </span>
                                            </span>
                                        </p>
                                    </td>
                                    <td style={{ backgroundColor: "#bfbfbf" }}>
                                        <p style={{ marginLeft: 2, marginRight: 3, textAlign: "left" }}>
                                            <span style={{ color: "#000000" }}>
                                                <span >
                                                    <span style={{ fontSize: "small" }}>
                                                        <strong>TYPE OF DATA</strong>
                                                    </span>
                                                </span>
                                            </span>
                                        </p>
                                    </td>
                                    <td style={{ backgroundColor: "#bfbfbf" }}>
                                        <p style={{ marginLeft: 2, marginRight: 3, textAlign: "left" }}>
                                            <span style={{ color: "#000000" }}>
                                                <span >
                                                    <span style={{ fontSize: "small" }}>
                                                        <strong>LAWFUL BASIS AND BUSINESS INTEREST</strong>
                                                    </span>
                                                </span>
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <p style={{ marginLeft: 2, marginRight: 3, textAlign: "left" }}>
                                            <span style={{ color: "#000000" }}>
                                                <span >
                                                    <span style={{ fontSize: "small" }}>
                                                        To engage with you after you have contacted us via the
                                                        Platform or otherwise
                                                    </span>
                                                </span>
                                            </span>
                                        </p>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Identity</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Contact</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Usage</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Marketing and Communications
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Express consent
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Performance of a contract with you
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Necessary for our legitimate interests (keep records
                                                                updated, study how users use our services, develop
                                                                services, grow)
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr>
                                    <td>
                                        <p style={{ marginLeft: 2, marginRight: 3, textAlign: "left" }}>
                                            <span style={{ color: "#000000" }}>
                                                <span >
                                                    <span style={{ fontSize: "small" }}>
                                                        To provide our services to you which includes creating an
                                                        account, using our services as either a Driver or Rider,
                                                        enabling any features within the Platform, providing users
                                                        with notifications and updates through the Platform
                                                        including email or other communications
                                                    </span>
                                                </span>
                                            </span>
                                        </p>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Identity</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Contact</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Verification</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Account</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Location</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Financial</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Technical</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Usage</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Transaction</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Support</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Performance of a contract with you
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Express consent{" "}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Necessary to comply with a legal obligation
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Necessary for our legitimate interests{" "}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr>
                                    <td>
                                        <p style={{ marginLeft: 2, marginRight: 3, textAlign: "left" }}>
                                            <span style={{ color: "#000000" }}>
                                                <span >
                                                    <span style={{ fontSize: "small" }}>
                                                        To contract with you as a service provider to Shesha
                                                    </span>
                                                </span>
                                            </span>
                                        </p>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Identity</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Contact</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Verification</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Account</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Location</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Financial</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Transaction</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Technical</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Usage</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Support</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Performance of a contract with you
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Express consent{" "}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Necessary to comply with a legal obligation
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Necessary for our legitimate interests{" "}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr>
                                    <td>
                                        <p style={{ marginLeft: 2, marginRight: 3, textAlign: "left" }}>
                                            <span style={{ color: "#000000" }}>
                                                <span >
                                                    <span style={{ fontSize: "small" }}>
                                                        To provide it to our authorised service providers who need
                                                        your personal information to provide their services to you{" "}
                                                    </span>
                                                </span>
                                            </span>
                                        </p>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Identity</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Verification Data
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Financial </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Performance of a contract with you
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Necessary for our legitimate interests
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Express consent
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr>
                                    <td>
                                        <p style={{ marginLeft: 2, marginRight: 3, textAlign: "left" }}>
                                            <span style={{ color: "#000000" }}>
                                                <span >
                                                    <span style={{ fontSize: "small" }}>
                                                        To process and service your payment for any services
                                                        rendered by Shesha or its service providers.
                                                    </span>
                                                </span>
                                            </span>
                                        </p>
                                        <p style={{ marginLeft: 2, marginRight: 3, textAlign: "left" }}>
                                            <span style={{ color: "#000000" }}>
                                                <span >
                                                    <span style={{ fontSize: "small" }}>
                                                        To manage payments, fees, and charges
                                                    </span>
                                                </span>
                                            </span>
                                        </p>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Identity</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Contact</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Account</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Location</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Financial</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Transaction</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Support</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Performance of a contract with you
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Necessary for our legitimate interests (make or
                                                                receive necessary payments)
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Express consent
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr>
                                    <td>
                                        <p style={{ marginLeft: 2, marginRight: 3, textAlign: "left" }}>
                                            <span style={{ color: "#000000" }}>
                                                <span >
                                                    <span style={{ fontSize: "small" }}>
                                                        To meet our regulatory and legal compliance requirements
                                                        including ensuring user safety fraud prevention and
                                                        security.{" "}
                                                    </span>
                                                </span>
                                            </span>
                                        </p>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Identity</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Contact</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Verification</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Location</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Financial</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Transaction</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Technical</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Support</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Performance of a contract with you
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Necessary for our legitimate interests{" "}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Express consent
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr>
                                    <td>
                                        <p style={{ marginLeft: 2, marginRight: 3, textAlign: "left" }}>
                                            <span style={{ color: "#000000" }}>
                                                <span >
                                                    <span style={{ fontSize: "small" }}>
                                                        To manage our relationship with you which may include
                                                        notifying you about changes to our Terms, Privacy Policy,
                                                        or Services
                                                    </span>
                                                </span>
                                            </span>
                                        </p>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Identity</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Contact</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Account</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Support</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Marketing and Communications
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Performance of a contract with you
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Necessary to comply with a legal obligation
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Necessary for our legitimate interests{" "}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Express consent
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr>
                                    <td>
                                        <p style={{ marginLeft: 2, marginRight: 3, textAlign: "left" }}>
                                            <span style={{ color: "#000000" }}>
                                                <span >
                                                    <span style={{ fontSize: "small" }}>
                                                        To administer and protect our company, Platform and
                                                        services (including troubleshooting, data analysis,
                                                        testing, system maintenance, support, reporting and
                                                        hosting of data)
                                                    </span>
                                                </span>
                                            </span>
                                        </p>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Account</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Location</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Technical</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Usage</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Support</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Necessary for our legitimate interests (running our
                                                                organisation, provision of administration and IT,
                                                                network security, to prevent fraud and organisation
                                                                restructuring exercise)
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Necessary to comply with a legal obligation
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Express consent
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr>
                                    <td>
                                        <p style={{ marginLeft: 2, marginRight: 3, textAlign: "left" }}>
                                            <span style={{ color: "#000000" }}>
                                                <span >
                                                    <span style={{ fontSize: "small" }}>
                                                        To use data analytics to improve our Platform, Services,
                                                        and user relationships and experiences
                                                    </span>
                                                </span>
                                            </span>
                                        </p>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Account</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Technical</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Usage</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Support</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Marketing and Communications
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Necessary for our legitimate interests{" "}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Express consent
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr>
                                    <td>
                                        <p style={{ marginLeft: 2, marginRight: 3, textAlign: "left" }}>
                                            <span style={{ color: "#000000" }}>
                                                <span >
                                                    <span style={{ fontSize: "small" }}>
                                                        To facilitate communication between users on the Platform
                                                    </span>
                                                </span>
                                            </span>
                                        </p>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Contact</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Account</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Location</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Support</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Express consent
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Performance of a contract with you
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Necessary for our legitimate interests{" "}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr>
                                    <td>
                                        <p style={{ marginLeft: 2, marginRight: 3, textAlign: "left" }}>
                                            <span style={{ color: "#000000" }}>
                                                <span >
                                                    <span style={{ fontSize: "small" }}>
                                                        To provide you with direct marketing by making suggestions
                                                        about services that may be of interest as well as
                                                        promotional offers
                                                    </span>
                                                </span>
                                            </span>
                                        </p>
                                    </td>
                                    <td>
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Identity</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Contact</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Account</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Technical</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Usage</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>Support</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Marketing and Communications
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                    <td>
                                        <ol start={4} style={{ listStyleType: "lower-alpha" }}>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Necessary for our legitimate interests (develop our
                                                                services and grow)
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{ marginRight: 3, textAlign: "left" }}>
                                                    <span style={{ color: "#000000" }}>
                                                        <span >
                                                            <span style={{ fontSize: "small" }}>
                                                                Express consent
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <u>Marketing</u>
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We strive to provide you with choices regarding certain personal
                                    information uses, particularly around marketing and advertising.
                                    To manifest your rights attached to any marketing sent to you as
                                    an existing Member, please use the in-built prompts provided on
                                    those communications, or contact us.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    You will receive marketing communications from us if you have
                                    requested our Services, requested information from us, or provided
                                    us with your details in any other circumstance and, in each case,
                                    have not opted-out of receiving that marketing.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    You can ask us to stop sending you marketing messages at any time
                                    by using the built-in prompts or contacting us and requesting us
                                    to cease or change your marketing preferences. Where you opt-out
                                    of receiving these marketing messages, this opt-out will not apply
                                    to other personal information of yours which we process for
                                    another lawful basis or necessary communications we send when you
                                    use our Services.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <u>Third-Party </u>
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <u>Marketing</u>
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    Whilst we may use your personal information within our company, we
                                    will get your express opt-in consent before we share your personal
                                    information publicly with any entity outside of Shesha for
                                    marketing.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <u>Change of Purpose</u>
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We will only use your personal information for the purposes for
                                    which we collected it, unless we reasonably consider that we need
                                    to use it for another reason and that reason is compatible with
                                    the original purpose. If you wish to get an explanation as to how
                                    the processing for the new purpose is compatible with the original
                                    purpose, please contact us.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    If we need to use your personal information for an unrelated
                                    purpose, we will notify you and we will explain the legal basis
                                    which allows us to do so.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    Please note that we may process your personal information without
                                    your knowledge or consent, in compliance with the above rules,
                                    where this is required or permitted by law.
                                </span>
                            </span>
                        </span>
                    </p>
                    <ol start={6}>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ fontSize: 18 }}>
                                    <span style={{ color: "#000000" }}>
                                        <span >
                                            <span >
                                                <strong>Disclosures Of Your Personal Information</strong>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We may have to share your personal information with the parties
                                    set out below for the purposes set out in the table above.
                                </span>
                            </span>
                        </span>
                    </p>
                    <ul>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    Internal third parties including other entities or parties
                                                    in the Shesha group acting as joint responsible parties or
                                                    operators.{" "}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    External third parties such as:{" "}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <ul>
                                <li>
                                    <p style={{ textAlign: "left" }}>
                                        <span style={{ color: "#000000" }}>
                                            <span >
                                                <span style={{ fontSize: "medium" }}>
                                                    <span >
                                                        <span style={{ fontSize: "small" }}>
                                                            Authorised third-party service providers under
                                                            contract with Shesha who need your personal
                                                            information in order to contact and transact with you
                                                            pursuant to your use of the Services;
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p style={{ textAlign: "left" }}>
                                        <span style={{ color: "#000000" }}>
                                            <span >
                                                <span style={{ fontSize: "medium" }}>
                                                    <span >
                                                        <span style={{ fontSize: "small" }}>
                                                            Users of the Platform including personal information
                                                            shared with Drivers and Riders to facilitate use of
                                                            the Platform and Driver services and personal
                                                            information shared at a user’s request such as
                                                            location data shared with a non-Platform user or to
                                                            emergency services;
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p style={{ textAlign: "left" }}>
                                        <span style={{ color: "#000000" }}>
                                            <span >
                                                <span style={{ fontSize: "medium" }}>
                                                    <span >
                                                        <span style={{ fontSize: "small" }}>
                                                            service providers acting as operators such as payment
                                                            processors, social media platforms, cloud storage
                                                            providers, customer support service providers,
                                                            marketing providers, verification agencies, location
                                                            integrations such as{" "}
                                                        </span>
                                                    </span>
                                                    <span >
                                                        <span style={{ fontSize: "small" }}>
                                                            <em>Google Maps</em>
                                                        </span>
                                                    </span>
                                                    <span >
                                                        <span style={{ fontSize: "small" }}>;</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p style={{ textAlign: "left" }}>
                                        <span style={{ color: "#000000" }}>
                                            <span >
                                                <span style={{ fontSize: "medium" }}>
                                                    <span >
                                                        <span style={{ fontSize: "small" }}>
                                                            South African or other national governments and/or
                                                            their respective authorities pursuant to our adherence
                                                            with anti-corruption and crime-fighting legislation;
                                                            and/or
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p style={{ textAlign: "left" }}>
                                        <span style={{ color: "#000000" }}>
                                            <span >
                                                <span style={{ fontSize: "medium" }}>
                                                    <span >
                                                        <span style={{ fontSize: "small" }}>
                                                            professional advisers acting as operators or joint
                                                            responsible parties including lawyers, bankers,
                                                            auditors and insurers based in South Africa who
                                                            provide consultancy, banking, legal, insurance and
                                                            accounting services as required.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    Third parties to whom we may choose to sell, transfer, or
                                                    merge parts of our company or our assets. Alternatively,
                                                    we may seek to acquire other organisations or merge with
                                                    them. If a change happens to our company, we may continue
                                                    to use your personal information in the same way as set
                                                    out in this Privacy Policy.
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ul>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We require all third parties to respect the security of your
                                    personal information and to treat it in accordance with the law.
                                    We do not allow our third-party service providers to use your
                                    personal information for their own purposes and only permit them
                                    to process your personal information in accordance with our
                                    instructions and standards.
                                </span>
                            </span>
                        </span>
                    </p>
                    <ol start={7}>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ fontSize: 18 }}>
                                    <span style={{ color: "#000000" }}>
                                        <span >
                                            <span >
                                                <strong>Cookies </strong>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    The Platform may make use of “cookies” to automatically collect
                                    information and data through the standard operation of the
                                    Internet servers. “Cookies” are small text files a platform can
                                    use (and which we may use) to recognise repeat users, facilitate
                                    the user’s on-going access to and use of a platform and allow a
                                    platform to track usage behaviour and compile aggregate data that
                                    will allow the platform operator to improve the functionality of
                                    the platform and its content, and to display more focused
                                    advertising to a user by way of third party tools.{" "}
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    The type of information collected by cookies is not used to
                                    personally identify you. If you do not want information collected
                                    using cookies, there is a simple procedure in most browsers that
                                    allows you to deny or accept the cookie feature. Please note that
                                    cookies may be necessary to provide you with certain features
                                    available on our Platform and thus if you disable the cookies on
                                    your browser you may not be able to use those features, and your
                                    access to our Platform will therefore be limited. If you do not
                                    disable “cookies”, you are deemed to consent to our use of any
                                    personal information collected using those cookies, subject to the
                                    provisions of this Privacy Policy and our other policies or terms.
                                </span>
                            </span>
                        </span>
                    </p>
                    <ol start={8}>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ fontSize: 18 }}>
                                    <span style={{ color: "#000000" }}>
                                        <span >
                                            <span >
                                                <strong>International Transfers</strong>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We may share and process your personal information outside of
                                    South Africa for the purpose of cloud storage or to engage with
                                    third parties.{" "}
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    Whenever we may transfer your personal information out of South
                                    Africa, we will ensure a similar degree of protection is afforded
                                    to it by ensuring at least one of the following safeguards is
                                    implemented:
                                </span>
                            </span>
                        </span>
                    </p>
                    <ul>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    We will only transfer your personal information to
                                                    countries that have appropriate data protection
                                                    legislation in place similar to that of South Africa;
                                                    and/or
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    Where we use service providers, we may use specific
                                                    contracts/clauses which ensure personal information is
                                                    processed and secured lawfully.
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ul>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    You are welcome to contact us if you want further information
                                    regarding transfer of personal information out of South Africa.
                                </span>
                            </span>
                        </span>
                    </p>
                    <ol start={9}>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ fontSize: 18 }}>
                                    <span style={{ color: "#000000" }}>
                                        <span >
                                            <span >
                                                <strong>Data Security</strong>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We have put in place appropriate security measures to prevent your
                                    personal information from being accidentally lost, used, or
                                    accessed in an unauthorised way, altered, or disclosed by using
                                    reasonable technical and organisational measures in line with best
                                    industry practice. We also limit access to your personal
                                    information to those employees, agents, contractors and other
                                    third parties who have a legitimate need to know. They will only
                                    process your personal information on our instruction and are
                                    subject to a duty of confidentiality.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We have put in place procedures to deal with any suspected
                                    personal information breach and will notify you and the
                                    Information Regulator of a breach where we are legally required to
                                    do so.
                                </span>
                            </span>
                        </span>
                    </p>
                    <ol start={10}>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ fontSize: 18 }}>
                                    <span style={{ color: "#000000" }}>
                                        <span >
                                            <span >
                                                <strong>Data Retention</strong>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We will only retain your personal information for as long as
                                    necessary to fulfil the purpose we collected it for including any
                                    legal, accounting, or reporting requirements.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    To determine the appropriate retention period for personal
                                    information, we consider the amount, nature, and sensitivity of
                                    the personal information, the potential risk of harm from
                                    unauthorised use or disclosure of your personal information, the
                                    purpose for which we process your personal information, any other
                                    South African applicable law requiring us to retain the personal
                                    information and whether we can achieve those purposes through
                                    other means, and the applicable legal requirements.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    You may request us to delete your personal data or account through
                                    the Platform or by contacting us directly. Following an account
                                    deletion request, we delete your account and data, except as
                                    necessary for purposes of safety, security, fraud prevention, tax,
                                    litigation, insurance claims or compliance with legal
                                    requirements, or because of issues relating to your account (such
                                    as an outstanding payments or any complaints or dispute).{" "}
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We may also anonymise your personal information (so that it can no
                                    longer be associated with you) for research or statistical
                                    purposes in which case we may use this information indefinitely
                                    without further notice to you.
                                </span>
                            </span>
                        </span>
                    </p>
                    <ol start={11}>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ fontSize: 18 }}>
                                    <span style={{ color: "#000000" }}>
                                        <span >
                                            <span >
                                                <strong>Your Legal Rights</strong>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    You have rights in relation to your personal information and how
                                    we are able to process it as a Responsible Party. Please contact
                                    us to find out more about, or manifest, these rights:
                                </span>
                            </span>
                        </span>
                    </p>
                    <ul>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    request access to your personal information;
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    request correction of your personal information;
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    request erasure of your personal information;
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    object to the processing of your personal information;
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    request a restriction of processing your personal
                                                    information;
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    request transfer of your personal information; and/or
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    right to withdraw consent.
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ul>
                    <p style={{ marginLeft: 28, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    You will not have to pay a fee to access your personal information
                                    (or to exercise any of the other rights). However, we may charge a
                                    reasonable fee if your request is clearly unfounded, repetitive,
                                    or excessive. Alternatively, we may refuse to comply with your
                                    request in these circumstances.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 28, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We may need to request specific information from you to help us
                                    confirm your identity and ensure your right to access your
                                    personal information (or to exercise any of your other rights).
                                    This is a security measure to ensure that personal information is
                                    not disclosed to any person who has no right to receive it. We may
                                    also contact you to ask you for further information in relation to
                                    your request to speed up our response.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 28, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We try to respond to all legitimate requests within one month.
                                    Occasionally it may take us longer than a month if your request is
                                    particularly complex or you have made a number of requests. In
                                    this case, we will notify you and keep you updated.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 28, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    Users with citizenships from jurisdictions other than South
                                    Africa, please note that we comply with South African data
                                    protection laws when processing your personal information as we
                                    are a South African entity. Should foreign law be applicable to
                                    your use of the Services and/or the Platform in any way, including
                                    how we may process your personal information, please contact us
                                    and we will gladly engage with you on your rights.{" "}
                                </span>
                            </span>
                        </span>
                    </p>
                    <ol start={12}>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    <strong>European User Rights: </strong>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    If you are located in the EU or Switzerland, you have certain
                                    rights with respect to your personal information under the
                                    European Commission’s General Data Protection Regulation, 2016/679
                                    (“
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    <strong>GDPR</strong>
                                </span>
                            </span>
                        </span>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>”).</span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    We are the Controller (as defined in GDPR) when you provide us
                                    with your personal information through your use of our Services.
                                    When we act as a Controller, we determine how your personal
                                    information will be used strictly in accordance with this Privacy
                                    Policy.
                                </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ marginLeft: 24, textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    When we process your personal information as the relevant
                                    Controller we will rely on several legal bases. These legal bases
                                    include:
                                </span>
                            </span>
                        </span>
                    </p>
                    <ul>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    where the processing is in terms of a legal contract with
                                                    you;
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    where you have given your express prior consent, which you
                                                    may withdraw at any time;
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    where the processing is necessary to comply with a legal
                                                    obligation or order of court; and
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p style={{ textAlign: "left" }}>
                                <span style={{ color: "#000000" }}>
                                    <span >
                                        <span style={{ fontSize: "medium" }}>
                                            <span >
                                                <span style={{ fontSize: "small" }}>
                                                    where the processing is necessary for the purposes of our
                                                    legitimate business interests.
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Privacy