import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import '../App.css';
import DriverR from '../assets/Driver Requirements.png';
import Fair from '../assets/Fair payment.png';
import ResI from '../assets/Respectful.png';
import Safe from '../assets/Safety.png';
import Lady from '../assets/driver-boy.png';
import STR from '../assets/driverStr.png';
import HomeTop from '../assets/driverT.jpg';
import StartDriver from '../assets/rready.png';
import Trans from '../assets/transparency.png';




function ForDriver() {
    const card = [
        {
            img: Fair,
            head: 'Fair Payment',
            p1: 'We value our drivers and believe in doing business in a fair way that benefits both parties; so, we only charge a 15% commission to ensure that you end up with more money in your pocket.',
            p2: "We don't have any hidden fees, and what you see in your wallet is completely yours."
        }, {
            img: ResI,
            head: 'RESPECTFUL ENVIRONMENT',
            p1: 'We encourage riders to treat our drivers with the utmost respect and courtesy.',
            p2: "Our rating system and feedback mechanisms ensure that your voice is heard and valued."
        }, {
            img: Safe,
            head: 'SAFETY FIRST',
            p1: 'Your safety is paramount. With features like fingerprint identification, identity verification, and driver ratings, we ensure that you are protected at every step.',
            p2: "       "
        }, {
            img: Trans,
            head: 'TRANSPARENT POLICIES',
            p1: "Our policies are clear, straightforward, and designed with you in mind. From pricing to payment, everything is transparent.",
            p2: "    "
        }
    ]
    return (
        <div >
            <div className="lg:min-h-[80vh] min-h-[50vh] bg-cover bg-no-repeat  " style={{ backgroundImage: `url(${HomeTop})`, boxShadow: 'rgba(0, 0, 0, 0.5) 500px 2000px inset', }} >
                <Slide direction='down' triggerOnce>
                    <div className="text-center pt-10">
                        <h1 className='text-3xl md:text-6xl lg:text-8xl font-bold text-white'>Refine Your Journey
                            <br />
                            <Fade delay={1000} triggerOnce duration={1000} cascade>
                                <div className='text-[#f62f02] font-bold lg:pt-3 text-2xl md:text-6xl lg:text-7xl '>

                                    Driver  <span className='text-white'>With</span>    Shesha!
                                </div>
                            </Fade>

                        </h1>
                    </div>
                </Slide>
            </div>
            <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 px-5 md:px-12 lg:px-20  items-center p-5'>
                <Slide direction='left' className='' triggerOnce>
                    <div className="p-2">
                        <h3 className='text-black text-lg md:text-3xl lg:text-5xl font-extrabold'>
                            WHY  <span className='text-[#f62f02]'> DRIVE</span> WITH
                            <br />
                            <span className='text-[#f62f02]'> SHESHA?</span>
                        </h3>
                        <div className="text-lg my-5 md:my-8 lg:my-10 flex items-center flex-col justify-center">
                            <p className='' >
                                At Shesha, we believe drivers are the cornerstone of our service. We've built a platform that values your contribution, respects your efforts, and supports your journey at every turn. If you are looking for a community that cares about your well-being and provides fair compensation, then Shesha is the place for you.
                            </p>
                            <br />

                        </div>

                    </div>
                </Slide>
                <Slide direction='right' triggerOnce>
                    <div className="">
                        <img src={Lady} alt="" />
                    </div>
                </Slide>


            </section>
            {/* card section */}
            <section className='min-h-[90vh] -z-10 flex justify-center items-start' style={{ background: `url(${STR}) `, backgroundPosition: 'center' }}>

                <div className="grid md:grid-cols-2 lg:grid-cols-4 place-items-start gap-5">
                    {card.map((e, i) => {
                        return (
                            <Fade direction='up' cascade delay={i * 150} triggerOnce>
                                <div className="card relative max-w-[18rem] border-2 p-10  my-5 lg:my-0  rounded-lg bg-white shadow-2xl">
                                    <div className="absolute-div     ">
                                        <img src={e.img} alt="" className=' p-4 text-center h-[80px] bg-[#f62f02] rounded-full  ' />
                                    </div>
                                    <div className="text-center pt-8">
                                        <h1 className='pb-2 font-extrabold'>{e.head}</h1>
                                        <p className='font-medium '>{e.p1}</p>
                                        <p className='font-medium py-3'>{e.p2}</p>
                                    </div>

                                </div>
                            </Fade>
                        )
                    })}


                </div>


            </section>
            {/* driver section */}
            <section className='grid grid-cols-1  md:grid-cols-2 lg:grid-cols-2 mt-5  items-start lg:items-center '>

                <Slide direction='left' triggerOnce>
                    <div className="">
                        <img src={DriverR} alt="" />
                    </div>
                </Slide>
                <Slide direction='right' className='' triggerOnce>
                    <div className="p-2">
                        <h3 className='text-black text-2xl md:text-3xl lg:text-5xl font-extrabold ms-4 lg:ms-0'>
                            DRIVER  <br /> <span className='text-[#f62f02]'> REQUIREMENTS</span>
                        </h3>
                        <div className="text-lg my-5 md:my-8 lg:my-10 flex items-center flex-col justify-center p-5 space-y-8 ">
                            <p className=''>To become a Shesha driver, you'll need the following documents: These documents are required to activate your account.</p>
                            <p className='' >
                                Additionally, your vehicle must be a 2015 model or newer and in decent condition.
                            </p>
                            <p className=''>Only vehicles that have passed the vehicle roadworthiness and shuttle evaluations tests will be permitted to operate on Shesha.</p>
                        </div>
                        <div className="">
                            <ul className='space-y-3'>
                                <li className='text-lg lg:text-2xl '>
                                    <div className="flex items-center gap-3 bg-white">
                                        <div className="border-[#f62f02] border-2  rounded-full  ">

                                            <p className='m-2 text-white  rounded-full px-3 py-1 bg-[#f62f02] font-bold '>1</p>
                                        </div>
                                        <p>Drivers Licence with
                                            PDP</p>
                                    </div> </li>
                                <li className='text-lg lg:text-2xl'>
                                    <div className="flex items-center gap-3 bg-white">
                                        <div className="border-[#f62f02] border-2  rounded-full  ">

                                            <p className='m-2 text-white  rounded-full px-3 py-1 bg-[#f62f02] font-bold '>2</p>
                                        </div>
                                        <p>Vehicle Inspection
                                            Report</p>
                                    </div> </li>
                                <li className='text-lg lg:text-2xl'>
                                    <div className="flex items-center gap-3 bg-white">
                                        <div className="border-[#f62f02] border-2  rounded-full  ">

                                            <p className='m-2 text-white  rounded-full px-3 py-1 bg-[#f62f02] font-bold '>3</p>
                                        </div>
                                        <p>Vehicle License</p>
                                    </div> </li>
                                <li className='text-lg lg:text-2xl'>
                                    <div className="flex items-center gap-3 bg-white">
                                        <div className="border-[#f62f02] border-2  rounded-full  ">

                                            <p className='m-2 text-white  rounded-full px-3 py-1 bg-[#f62f02] font-bold '>4</p>
                                        </div>
                                        <p>Certified South African Identity Document Copy</p>
                                    </div> </li>
                            </ul>
                        </div>

                    </div>
                </Slide>


            </section>
            <section className='lg:min-h-[90vh] min-h-[40vh]   bg-contain  mt-5  flex justify-center items-end' style={{ background: `url(${StartDriver}) `, boxShadow: 'rgba(0, 0, 0, 0.5) 500px 2000px inset' }}>
                <div className="text-center text-white p-10 space-y-8  lg:max-w-[70%] mb-10 ">
                    <h2 className='text-2xl md:text-3xl lg:text-5xl font-extrabold '>READY TO DRIVE WITH DIGNITY?</h2>
                    <p className='text-lg md:text-2xl lg:text-3xl font-bold '>Register today to become a Shesha driver and join a community that values and respects you. Click the link below to start your
                        application process.</p>
                    <a href="https://play.google.com/store/apps/details?id=com.shesha.driver.elite&hl=en&gl=US" target='_blank' className='pt-3 mt-5 block'>
                        <button className='text-lg px-5 py-3 font-extrabold bg-white text-black'>REGISTER <span className='text-[#f62f02] '>NOW</span> </button>
                    </a>
                </div>

            </section>
        </div>
    )
}

export default ForDriver