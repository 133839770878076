import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import { IoCheckmarkCircle } from 'react-icons/io5';
import Logo from '../assets/logo.png';

function Verify() {
    return (
        <div className='min-h-[100vh] flex justify-center flex-col gap-5 items-center bg-gray-200'>
            <div className='cursor-pointer' >
                <img src={Logo} alt="" className=" h-[50px] md:h-[85px] lg:[300px] " />
            </div>
            <Slide direction='up' duration={1500} className='max-w-[650px]  bg-white mx-3 shadow-xl border-b-8 p-3 rounded-sm border-[#f62f02]  flex text-center justify-center items-center flex-col'>

                <div className="mx-2">

                    <Fade delay={1500} className='flex justify-center'>

                        <IoCheckmarkCircle className='text-[#28a745] text-[80px] md:text-[70px]' />
                    </Fade>
                    <h1 class="text-xl md:text-2xl my-2">Verified </h1>
                    <div class="text-center my-3 md:text-lg ">
                        <p>This vehicle has been verified by Shesha E-hailing Services (Pty) Ltd - 2023/577025/07</p>
                    </div>
                </div>
            </Slide>

        </div>
    )
}

export default Verify