import React from 'react'

function Terms() {
    return (
        <div className='mt-10 p-5  lg:p-10'>
            <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: 20 }}>
                    <span style={{ fontFamily: "Calibri,serif" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <strong>TERMS OF USE </strong>
                            </span>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: "12pt" }}>
                    <span style={{ fontFamily: "Calibri,serif" }}>
                        <span style={{ color: "#000000" }}>
                            <span >
                                <span style={{ fontSize: "small" }}>
                                    Last updated: 28 March 2024
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ textAlign: "center" }}>
                <br />
                &nbsp;
            </p>
            <ol>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Introduction and Legal Terms</strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            By accessing or using our website,{" "}
                            <a
                                href="https://www.shesharide.co.za/"
                                style={{ color: "#0563c1", textDecoration: "underline" }}
                            >
                                https://www.shesharide.co.za/
                            </a>{" "}
                            or our application (collectively, “<strong>the Platform</strong>”), or
                            any of our services, you agree that you have read, understood, and
                            agree to be bound to the terms and conditions contained herein (“
                            <strong>Terms</strong>”). All rights in and to the content of the
                            Platform always remain expressly reserved by Shesha.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <span style={{ backgroundColor: "#ffffff" }}>
                                These Terms explain{" "}
                            </span>
                            the
                            <span style={{ backgroundColor: "#ffffff" }}>
                                {" "}
                                conditions applicable to how you will use the Platform. Please read
                                these Terms carefully before using the Platform. We will assume you
                                have read and understood these Terms if you continue to access or
                                make use of our Platform.{" "}
                            </span>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <span style={{ backgroundColor: "#ffffff" }}>
                                Please pay specific attention to the
                            </span>
                            <strong>
                                <span style={{ backgroundColor: "#ffffff" }}>
                                    {" "}
                                    BOLD paragraphs{" "}
                                </span>
                            </strong>
                            <span style={{ backgroundColor: "#ffffff" }}>
                                of these Terms. These paragraphs limit our risk or liability,
                                constitute an assumption of risk or liability by you, impose an
                                obligation on you to indemnify us, or are an acknowledgement of any
                                fact by you. We may immediately terminate these Terms or any
                                Services with respect to you, or generally cease offering or deny
                                access to the Services or any portion thereof, at any time for any
                                reason in our discretion.
                            </span>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <span style={{ backgroundColor: "#ffffff" }}>
                                The terms "user", “you" and “your” are used interchangeably in these
                                Terms and accordingly refer to anyone using the Platform as a
                                driver. Accordingly, the terms “us”, “our” or “we” refers to Shesha
                                or our possession.
                            </span>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <span style={{ backgroundColor: "#ffffff" }}>
                                These Terms must be read in conjunction with any supplemental terms
                                or policies that we implement from time to time. Any supplemental
                                terms or policies will be disclosed to you beforehand and where
                                applicable are in addition to, and deemed to be incorporated within,
                                these Terms.{" "}
                            </span>
                        </span>
                    </span>
                </span>
            </p>
            <ol start={2}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Our Services </strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 18 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            Through our Platform, we offer the technology that enables independent
                            drivers (“<strong>Drivers</strong>”) to provide their private
                            transportation and ride hailing services directly to customers (“
                            <strong>Riders</strong>”) via the Platform (collectively, “
                            <strong>Services</strong>”).{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 18 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            We do not provide any transportation services directly and therefore
                            are not involved in the private relationship between Drivers and
                            Riders. Drivers are independent third parties and are not employed by
                            Shesha.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <ol start={3}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Registering an Account with Us</strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Creating a Profile</u>: To join the Platform we require you to
                            create a profile with us. We ask you to provide us with all the
                            requested information in the on-boarding process including your full
                            name, identity or passport number, vehicle registration and financial
                            information.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Vetting of Information</u>: Our number one priority is safety; we
                            therefor verify the information of all our users to ensure we keep our
                            Platform safe. To ensure you are eligible for the Services, we process
                            your identity and/or passport number through the Department of Home
                            Affairs database to confirm your identity and information provided.
                            This validation service is facilitated by a third party under contract
                            with us and is only shared, processed, and retained for the purpose of
                            verification. We also verify your banking details with your listed
                            bank.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Accurate Information</u>: When signing up to our Platform you agree
                            to provide accurate, current, and complete information and to update
                            this information as and when it changes. To protect your privacy and
                            security, we take reasonable steps to verify your identity by
                            requiring your password together with your email address to grant you
                            access to your profile and information. Please update your information
                            yourself through your profile or contact us to view or change your
                            personal information provided.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>One Account</u>: You may use only your registered account to
                            participate in our Services. Unless we agree, you may not attempt to
                            establish multiple accounts using multiple email accounts, computers,
                            names, and/or identity information, or any program that masks your
                            identity or generates a fake identity. We reserve the right to
                            withhold, deny or cancel any Services and/or terminate your account if
                            we, in our sole discretion, deem your account as fraudulent, abusive,
                            unethical, suspicious, or otherwise inconsistent with our Terms or any
                            other applicable law or regulation.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Warranty</u>: By sharing your personal information with us, you
                            warrant that you are the person using the Platform. You are
                            responsible for the information you provide, and all the actions
                            associated with your account that are taken on the Platform. Please
                            see our Privacy Policy for more details on how we use and process
                            personal information.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>SMS</u>: By creating an account with us, you agree that we may send
                            you SMS messages as part of your use of the Services which may include
                            receipt of One-Time-Pin codes or service notifications. You may
                            opt-out of receiving SMS at any time but you acknowledge that doing so
                            may impact your use of the Services.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Unlawful Access</u>: Please let us know if you have reason to
                            believe that your account is no longer secure (for example, in the
                            event of a loss, theft or unauthorized disclosure or use of your
                            email, or password, or any payment card information) to avoid possible
                            liability for any unauthorized charges to your account.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Processing Personal Data</u>: We process personal information in
                            accordance with our Privacy Policy. You acknowledge that we may share
                            your personal information with third parties who assist us in
                            providing our Services.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <ol start={4}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Payment Terms</strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Registration Fee</u>: When registering for the Platform, we request
                            the payment of a once-off administration fee to register your
                            application with the Platform. The administration fee will be
                            displayed to you on the Platform before proceeding. Once we receive
                            payment of the administration fee your account will be confirmed and
                            created on the Platform.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Driver Payment</u>: As a driver you will receive payment for the
                            rides you accept through the Platform. Your earnings from these rides
                            will be paid directly into your wallet on the Platform which you can
                            withdraw at any time for payment directly into your chosen bank
                            account.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Rider Payment</u>: When joining the Platform as a Rider, you will
                            receive a wallet on the Platform, which you will be responsible for
                            loading with sufficient funds to facilitate your payment for services
                            from Drivers. We facilitate your payment of all applicable fees and
                            charges on behalf of Driver’s and their limited payment collection
                            agent using the services of our payment gateway. Payment of fees in
                            this manner will be considered the same as payment made directly by
                            you to the Driver. All fees are due and payable following receipt of
                            the services from a Driver and will be deducted from your wallet by
                            Shesha, as the Driver’s limited agent. Where there are insufficient
                            funds available in your wallet to facilitate payment, you agree that
                            we may use a secondary payment method available in your account where
                            possible.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Invoices and Statements</u>: You will receive an invoice for any
                            payments made via the Platform which are also available in your
                            account.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Charges and Cancellation</u>: You are responsible for all fees and
                            charges incurred under your account regardless of your awareness of
                            such charges or the amounts thereof. Fees on the Platform shall
                            include, but are not limited to, all applicable service fees, tolls,
                            and/or surcharges including booking fees, any national, provincial
                            and/or municipal tolls, airport surcharges and/or administrative fees
                            for split payments and is inclusive of value added tax. You may cancel
                            your request for services from a Driver at any time prior to the
                            Driver’s arrival, in which case you may be charged a cancellation fee.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Promotional Codes and Discounts</u>: We may, from time to time,
                            provide certain users with promotional offers, codes, or discounts for
                            the Services in our discretion (“<strong>Benefits</strong>”) that may
                            result in different amounts charged for the same or similar Services
                            and you agree that such Benefits, unless also made available to you,
                            shall have no bearing on your use of the Services or the fees applied
                            to you. Any awarded Benefits can only be used on the Platform and are
                            not redeemable for cash or any other Benefit. Benefits must be used
                            for their intended purpose and must not be manipulated, duplicated,
                            abused, or transferred for any reason. We reserve the right to
                            withhold or deduct any Benefits you or any other user receives from us
                            if we determine or believe that your use or redemption of the Benefit
                            was in error, fraudulent, illegal, or in violation of these Terms.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Changes to Fees</u>: We reserve the right to establish, remove,
                            and/or revise our fees at any time in our sole discretion. Further,
                            you acknowledge and agree our fees may increase substantially during
                            times of high demand. We do our best to notify you of changes to our
                            fees before incurring in costs.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Limitation of Use</u>: Your right to use our Platform is subject to
                            any limits we or your credit card issuer may establish. If payment
                            cannot be charged to your payment card or a charge is returned for any
                            reason, including chargeback, we reserve the right to either suspend
                            or terminate your account and/or access to the Platform.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Refunds</u>: Shesha does not offer refunds for ride-hailing fees
                            paid for any completed trips. All trips are billed once the trip has
                            been marked as completed on both the driver and ride applications and
                            therefore are not eligible for any refunds.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Payment Service Provider</u>: To ensure all payments are lawful and
                            secure, we use the services of Ozow as our authorised payment service
                            provider. Ozow manages the movement of funds to and from all user
                            wallets on the Platform. By using the Platform and our Services, you
                            consent to the{" "}
                            <a
                                href="https://ozow.com/terms-and-conditions"
                                style={{ color: "#0563c1", textDecoration: "underline" }}
                            >
                                terms and conditions of Ozow
                            </a>{" "}
                            through the use of its services.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <ol start={5}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Rating Users</strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ fontFamily: "Calibri,serif" }}>
                        <span style={{ color: "#000000" }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    To improve our Service, at the end of each trip, we ask both the
                                    Driver and the Rider to rate each other on the Platform.{" "}
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ fontFamily: "Calibri,serif" }}>
                        <span style={{ color: "#000000" }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    Riders are offered the opportunity to rate the Driver on five
                                    factors including cleanliness of the vehicle, condition of the
                                    vehicle inside and outside, Driver behaviour, Driver attitude, the
                                    Driver’s driving, and the general feel of the trip.{" "}
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ fontFamily: "Calibri,serif" }}>
                        <span style={{ color: "#000000" }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    Drivers can assess Riders based on their attitude and behaviour
                                    during the trip.
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ fontFamily: "Calibri,serif" }}>
                        <span style={{ color: "#000000" }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    If you have any feedback during or after your trip as a Rider, you
                                    are welcome to contact our customer service to submit any
                                    complaints, compliments, or comments.{" "}
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </p>
            <ol start={6}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Vehicle Collisions and Lost Property</strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Lost Property</u>: Riders are responsible for ensuring that they
                            retain and remove their property from any Driver vehicle once a trip
                            has been completed. Shesha is not responsible for the loss of or
                            damage to any property of a Rider during and subsequent to any journey
                            taken with a Driver. Where any property has been left behind, the
                            Rider may follow the procedure in the Platform and submit a note to
                            Shesha to request help in having their lost property returned. Shesha
                            will communicate with the Driver to assist the Rider in the return of
                            their property but cannot guarantee that the Rider will receive their
                            property back.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Vehicle Collisions</u>: Shesha is not responsible for any damages
                            sustained by either a Driver, a Driver vehicle and/or a Rider during
                            or subsequent to any journey taken with a Driver. Users of the
                            Platform do so at their own risk and are required to use all available
                            statutory mechanisms for recourse for damages suffered as a result of
                            any vehicle collision such as application to the Road Accident Fund.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <ol start={7}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Responsibilities and Warranties</strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Platform Warranties</u>: by using the Platform and/or the Services,
                            Riders and/or Drivers, warrant to and in favour of Shesha that -{" "}
                        </span>
                    </span>
                </span>
            </p>
            <ul>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    they{" "}
                                    <span style={{ color: "#222222" }}>
                                        <span style={{ backgroundColor: "#ffffff" }}>have</span>
                                    </span>{" "}
                                    read and agreed to these Terms and will use the Platform and
                                    Services in accordance with them;
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        they have not made any misrepresentations and the information
                                        provided by them during the registration process is true,
                                        accurate and complete in every aspect;
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        they are above the age of 18 (eighteen) years old and have the
                                        legal capacity to understand, agree with, and be bound by these
                                        Terms;{" "}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        where a Rider seeks to request a trip for any Rider under the
                                        age of 18, they warrant that they will accompany such Rider on
                                        the trip and be responsible for their actions and conduct during
                                        the trip;{" "}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        they lawfully possess and submit all information to Shesha for
                                        the use of the Platform and the Services and hereby indemnify
                                        Shesha against any third-party claims that may arise due to the
                                        processing of the information shared by them with Shesha;
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        where they use our Services on behalf of their company or
                                        organisation, they warrant that they have the authority and
                                        consent to sign-up to our Platform and share personal
                                        information with us and indemnify Shesha against any third-party
                                        claims that may arise in respect the sharing of personal
                                        information;
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        they will respect all other Shesha users and will treat and
                                        interact with either a Driver or Rider with respect and shall
                                        not under any circumstance act in a manner than can be construed
                                        as racist, sexist, discriminatory, abusive, harassing,
                                        confrontational, and/or threatening;{" "}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        they will never perform, attempt to perform or intend to perform
                                        any act which amounts to sexual misconduct, sexual harassment or
                                        sexual assault whilst providing or receiving any services by way
                                        of the Platform;{" "}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        they will not post, upload, replicate, or transmit any content
                                        on the Platform that is or could reasonably be considered to be
                                        threatening, harassing, defamatory, abusive, racist, sexist,
                                        discriminatory, in breach of confidence, in breach of privacy,
                                        or restrict any user in any way from properly using the
                                        Platform;
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        they will not send any unsolicited electronic messages or use
                                        any software, routine, or device to interfere or attempt to
                                        interfere electronically or manually with the operation or
                                        functionality of the Platform including (but not limited) to
                                        uploading or making available files containing corrupt data or
                                        viruses via whatever means or deface, alter, or interfere with
                                        the front end ‘look and feel’ of the Platform or the underlying
                                        software code;
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        they will not intentionally damage the property of another user
                                        or a Driver including, but not limited to, through the spilling
                                        of any food or drinks, smoking within the vehicle, damaging a
                                        cellphone or device, damage to the vehicle or any vomiting or
                                        defecation within a vehicle (unless under emergency
                                        circumstances);{" "}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        Drivers will complete trips using a vehicle which meet Shesha’s
                                        vehicle specification guidelines and that are verified and
                                        approved by Shesha beforehand;{" "}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        All users will wear their seatbelts when embarking on any trip
                                        to ensure the safety of all persons involved;{" "}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        Riders must ensure that a vehicle is requested that can
                                        accommodate the appropriate number of riders and must not
                                        request a vehicle that does not have sufficient seating for the
                                        number of riders in their party;{" "}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        Drivers are appropriately licensed and carry all necessary
                                        permits to allow them to provide their services to Shesha at all
                                        times and shall abide by all statutory and legal road rules and
                                        regulations during their provision of their services;{" "}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        Drivers will ensure that their vehicle is maintained in good
                                        working order and condition;{" "}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        No Driver may provide any service under the influence of alcohol
                                        or drugs and no Rider may consume or distribute any alcohol or
                                        drugs during any trip requested by way of the Platform;{" "}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        Riders shall not interfere with a Driver whilst they are driving
                                        and understand that doing so may place themselves and/or the
                                        Driver in danger and subject to a possible vehicle collision or
                                        worse;{" "}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        you will not infringe the intellectual property or other rights
                                        of any third party or the Platform or transmit content that you
                                        do not own or do not have the right to publish or distribute;
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        you will not use the Platform for any commercial purpose other
                                        than as expressly provided for by Shesha herein;
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        you will not use the Platform to breach any applicable law or
                                        regulation or perform or encourage any illegal activity
                                        including (without limitation) promoting or facilitating money
                                        laundering or financial crimes; and/or
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <span style={{ backgroundColor: "#ffffff" }}>
                                        you will not facilitate or assist any third party to do any of
                                        the above,
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ul>
            <p style={{ marginLeft: 62, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <span style={{ backgroundColor: "#ffffff" }}>
                                failing which, such action will automatically and immediately be
                                deemed to be a material breach of these Terms, allowing Shesha to
                                exercise any or all of our rights in the case of breach, including
                                but not limited to denying you access to the Platform/Services,
                                reporting your actions to an applicable authority or instituting
                                legal proceedings against you.{" "}
                            </span>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Connected Devices</u>: The Platform is only available on compatible
                            devices connected to the internet. It is your responsibility to obtain
                            these devices and any connectivity necessary to use the Platform. We
                            do not guarantee that the Platform, or any portion thereof, will
                            function on any particular hardware or device.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>
                                <strong>Access to Platform in Breach</strong>
                            </u>
                            <strong>
                                : Without prejudice to any of our other rights (whether at law or
                                otherwise), we reserve the right to deny you access to the Platform
                                where we believe (in our sole reasonable discretion) that you are in
                                breach of any of these Terms.
                            </strong>
                        </span>
                    </span>
                </span>
            </p>
            <ol start={8}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Messages and Advertising</strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Data Messages between You and Shesha</u>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            Data messages, including email messages, you send to us will be
                            considered as received only when we acknowledge or respond to these
                            messages.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            Data messages we send to you will be regarded as received when the
                            data message enters your email sever inbox and is capable of being
                            retrieved and processed by you.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            We reserve the right not to respond to any email or other data message
                            that contains obscene, threatening, defamatory, or otherwise illegal,
                            unlawful, or inappropriate content, and to take appropriate action
                            against the sender of such email or data message if necessary.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            Messages sent over the internet cannot be guaranteed to be completely
                            secure as they can be intercepted, lost, or corrupted. We are
                            therefore not responsible for the accuracy or safety of any message
                            sent by email or over the internet.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 94, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Hyperlinks, Deep Links, Framing</u>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            The Platform may include links to other websites ("
                            <strong>other sites</strong>"). We do not own or endorse these other
                            sites and are not responsible for the information, material, products,
                            or services contained on or accessible through these other sites. Any
                            hyperlinks do not imply any endorsement, agreement on or support of
                            the content or products of these other sites.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            We do not own the content on any other site which may be shown on the
                            Platform. Should the owner of any content showcased on the Platform
                            want the content to be removed, please contact us to request the
                            removal of such content.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            Your access and use of the other sites remain solely at your own risk
                            and on the terms set by the operator of any other site.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Advertising</u>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            The Platform may contain advertising and sponsorship. Advertisers and
                            sponsors are responsible for ensuring that material submitted for
                            inclusion on the Platform complies with all applicable laws and
                            regulations.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            Shesha its shareholders, directors, employees, suppliers, partners,
                            affiliates, and agents accordingly exclude, to the maximum extent
                            permitted in law, any responsibility or liability for any error or
                            inaccuracy appearing in advertising or sponsorship material on the
                            Platform.
                        </span>
                    </span>
                </span>
            </p>
            <ol start={9}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Intellectual Property</strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Platform IP</u>: All website layout, website content, material,
                            illustrations, information, data, software, icons, text, graphics,
                            layouts, images, sound clips, advertisements, video clips, user
                            interface design and layout, trade names, logos, trademarks, designs,
                            copyright and/or service marks, together with the underlying software
                            code, (“<strong>the intellectual property</strong>”) are owned (or
                            co-owned or licenced, as the case may be) by Shesha, our shareholders,
                            associates and/or partners, whether directly or indirectly, and as
                            such, are protected from infringement by domestic and international
                            legislation and treaties.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>User submitted IP</u>: All rights to any intellectual property you
                            provide to us will remain with you, but for which you have provided us
                            with a non-exclusive, non-transferable licence to use such
                            intellectual property to provide you with our Services including the
                            use of our Platform.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>No Modification of IP</u>: Subject to the rights afforded to you in
                            these Terms, all other rights to all intellectual property on the
                            Platform are expressly reserved
                            <strong>
                                . You may not copy, download, print, modify, alter, publish,
                                broadcast, distribute, sell, or transfer any intellectual property,
                                editorial content, graphics, or other material or the underlying
                                software code whether in whole or in part, without our written
                                consent first being granted, which consent may be refused at our
                                discretion. No modification of any intellectual property or graphics
                                is permitted. Should you breach these provisions, we and/or the
                                rightful intellectual property rights owner may launch legal
                                proceedings against you for a breach of contract, resulting in a
                                claim of damages against you.
                            </strong>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Updates</u>: We reserve the right to make improvements or changes
                            to the intellectual property, information, videos, graphics, and other
                            materials on the Platform/Services, or to suspend or terminate the
                            Platform, at any time without notice; provided that any transactions
                            or functions already concluded, will not be affected by such
                            suspension or termination (as the case may be).{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Third Party IP</u>: Where any intellectual property has been
                            licensed to us or belongs to any third party all rights of use will
                            also be subject to any terms and conditions which that licensor or
                            third party imposes from time to time, and you agree to comply with
                            such third-party terms and conditions.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>User License</u>: Subject to adherence to the Terms, we grant to
                            you a personal, non-exclusive, non-assignable, and non-transferable
                            license to use and display all content and information on any machine
                            which you are the primary user. However, nothing contained on the
                            Platform or in these Terms should be construed as granting any licence
                            or right to use any intellectual property without our prior written
                            permission.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <ol start={10}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Confidentiality</strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            The Driver acknowledges that any information, documents, materials,
                            knowledge, know-how, trade secrets and proprietary interests vesting
                            in and belonging to Shesha and/or an associated company, disclosed to
                            them and/or their representatives at any time by or on behalf of
                            Shesha which is not in the public domain is confidential. The
                            aforementioned may not be used or disclosed to any third party
                            (whether during the negotiations preceding, during the course of
                            and/or after the termination of our relationship) for any reason
                            whatsoever save as may be strictly necessary for the due and effectual
                            rendering of their services on the Platform and in accordance with
                            law.
                        </span>
                    </span>
                </span>
            </p>
            <ol start={11}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Indemnities and Disclaimers</strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Disclaimers</u>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <a name="tyjcwt" />{" "}
                        <span >
                            <strong>
                                The Platform and Services, including intellectual property appearing
                                therein, are provided "as is" and "as available". We make no
                                representations or warranties, express or implied, including but not
                                limited to warranties as to the accuracy, correctness, or
                                suitability of the Platform or any warranties, representations or
                                guarantees as to as to reliability, timeliness, quality, suitability
                                or availability of the Services or any services requested through
                                the use of the Services/Platform.
                            </strong>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <strong>
                                All Drivers are independent third parties and not in any way
                                employed by Shesha. Shesha therefore does not guarantee the quality,
                                suitability, safety, or ability of Driver’s providing services on
                                the Platform. Riders agree that the entire risk arising out of use
                                of the Services, and any service requested from a Driver in
                                connection therewith, remains solely with the Rider, to the maximum
                                extent permitted under law.
                            </strong>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            All content, information, and/or opinions of users made available on
                            the Platform in relation to any of the Services are those of the
                            authors and not Shesha. While we make every reasonable effort to
                            present such information accurately and reliably on the Platform we do
                            not endorse, approve, or certify such information, nor guarantee the
                            accuracy or completeness of such information on the Platform.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <strong>
                                Shesha, its shareholders, directors, employees, agents, office
                                bearers, and partners, accept no liability whatsoever for any loss,
                                whether direct or indirect, consequential, or arising from
                                information made available on (or by means of) the Platform and/or
                                transactions or actions resulting therefrom or from the Services
                                offered by Shesha or any Driver through the Platform.{" "}
                            </strong>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <strong>
                                Shesha, its shareholders, directors, employees, partners, and
                                affiliates, accept no liability whatsoever for any costs, expenses,
                                fines, or damages, including but not limited to direct or indirect
                                loss or damages, including any economic loss, consequential loss,
                                loss of profits or any form of punitive damages, resulting from the
                                facilitation and offering of the Services, and access to, or use of,
                                the Platform in any manner.{" "}
                            </strong>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <a name="2s8eyo1" />{" "}
                        <span >
                            We take reasonable security measures to ensure the safety and
                            integrity of the Platform and to exclude viruses, unlawful monitoring
                            and/or access from the Platform offline. However, we do not warrant or
                            represent that your access to the Platform will be uninterrupted or
                            error-free or that any information, data, content, software, or other
                            material accessible through the Platform will be free of bugs,
                            viruses, worms, trojan horses or other harmful components. Your access
                            to and use of the Platform remains solely at your own risk, and you
                            should take your own precautions accordingly.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 94, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        &nbsp;
                        <span >
                            <u>Indemnities</u>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <a name="26in1rg" />{" "}
                        <span >
                            <strong>
                                You indemnify and hold harmless Shesha, its shareholders, employees,
                                and partners from any demand, action or application or other
                                proceedings, including for attorneys’ fees and related costs such as
                                tracing fees, made by any third party, and arising out of or in
                                connection with your use of the Platform and/or Services offered, or
                                transactions concluded through the Platform in any way.
                            </strong>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <strong>
                                You agree to indemnify, defend, and hold Shesha, its shareholders,
                                employees, and partners harmless from any direct or indirect
                                liability, loss, claim and expense (including reasonable legal fees)
                                related to the user’s breach of these Terms.
                            </strong>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <strong>This clause will survive termination of these Terms. </strong>
                        </span>
                    </span>
                </span>
            </p>
            <ol start={12}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                &nbsp;
                                <span >
                                    <strong>Dispute Resolution between you and Shesha</strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Negotiation</u>: Should any dispute, disagreement or claim arise
                            between you and Shesha concerning the use of the Platform or the
                            Services, the parties shall endeavour to resolve the dispute amicably,
                            by negotiation, and with the best interests of both parties in mind.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Mediation</u>: Should these parties fail to resolve such dispute in
                            the aforesaid manner or within such further period as the parties may
                            agree to in their negotiation, the parties may approach an independent
                            industry expert who shall mediate the discussions between them to find
                            a mutually beneficial solution.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Arbitration</u>: If the dispute is still not resolved after such
                            mediation, the parties may consent to commence and be party to binding
                            and confidential arbitration in terms of the expedited rules of the
                            Arbitration Foundation of Southern Africa (“<strong>AFSA</strong>”),
                            with an arbitrator selected by Shesha.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Jurisdiction</u>: Notwithstanding the above, both parties' consent
                            to the jurisdiction of an appropriate South African court. Either
                            party may also always use the dispute resolution services of any
                            applicable legislative tribunal or ombud, as provided for in
                            applicable legislation.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>No publication</u>: The parties both agree that in no circumstance
                            will either party publicise the dispute on any social media or other
                            public platforms. The parties understand that any publicity of this
                            nature can cause serious damage to the other party, which damage may
                            result in a financial claim against the infringing party.
                        </span>
                    </span>
                </span>
            </p>
            <ol start={13}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Disputes between Users</strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Private Dispute</u>: Should a dispute arise between Drivers and
                            Riders and their private transaction, said dispute is between these
                            parties exclusively. Shesha is not responsible for fulfilling any
                            function in any way or engaging in the dispute in any way.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Beneficial Resolution</u>: The users agree that they will resolve
                            their dispute in a manner that is mutually agreeable and/or as
                            prescribed by any relevant agreement concluded between them, and for
                            the attempted benefit of both parties.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Notification</u>: Notwithstanding the above, these parties must
                            inform Shesha of the dispute for Shesha to log the issues experienced,
                            and to try assist both parties in whatever way it deems fit but is
                            under no obligation to do so.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <ol start={14}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Termination of Use </strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <a name="_1ksv4uv" />{" "}
                        <span >
                            <strong>
                                IN ADDITION TO OUR OTHER RIGHTS HEREIN, WE RESERVE THE RIGHT TO
                                RESTRICT AND/OR TERMINATE YOUR USE OF OUR PLATFORM IF YOU BREACH ANY
                                OF THESE TERMS, OR{" "}
                            </strong>
                            <u>
                                <strong>FOR ANY OTHER REASON IN OUR SOLE DISCRETION</strong>
                            </u>
                            <strong> PROVIDED THAT WE GIVE REASONABLE NOTICE TO YOU.</strong>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            If you wish to terminate your agreement with us and these Terms, you
                            may do so by ending your use of our Platform. Such termination will
                            however not have any effect on the continued and comprehensive
                            functioning or legitimacy of any lawful rights which we may have at
                            the time of said termination.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            In the event of termination of your agreement with these Terms we will
                            remove you from the Platform and delete your profile in accordance
                            with our data retention requirements.
                        </span>
                    </span>
                </span>
            </p>
            <ol start={15}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ color: "#000000" }}>
                            <span style={{ fontSize: 18 }}>
                                &nbsp;
                                <span >
                                    <strong>Notices and Service Address</strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <span >E</span>ach of the parties
                            chooses their service address for the purposes of the giving of any
                            notice, the serving of any process and for any other purposes arising
                            from these Terms as being:
                        </span>
                    </span>
                </span>
            </p>
            <ul>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    in the case of Shesha, at{" "}
                                    <a
                                        href="mailto:info@shesharide.co.za"
                                        style={{ color: "#0563c1", textDecoration: "underline" }}
                                    >
                                        info@shesharide.co.za
                                    </a>
                                    ; or
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    in the case of a user, at the e-mail, cellphone number, and/or
                                    address provided when registering with us.{" "}
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ul>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            Each of the parties will be entitled from time to time, by written
                            notice to the other to vary its service address to any other address
                            which is not a post office box or poste restante, provided that the
                            change will become effective only 14 (fourteen) days after service of
                            the notice in question.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            Notwithstanding the above, any notice given in writing in English, and
                            actually received by the party to whom the notice is addressed, will
                            be deemed to have been properly given and received, notwithstanding
                            that such notice has not been given in accordance with this clause.
                        </span>
                    </span>
                </span>
            </p>
            <ol start={16}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Complaints and Support Enquiries</strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            Should you have any complaints or support enquiries, please use the
                            built-in prompts on the Platform to contact us directly.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            On the Platform's "Home Page," click the "Help" button in the top
                            right corner. Choose a relevant option from the drop-down menu. Type
                            your complaint, question, comment, or inquiry and thereafter click the
                            submit button.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 29, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            Shesha's Help Desk will receive your inquiry and notify you
                            accordingly.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <ol start={17}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Company Information</strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <ul>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Site owner: </strong> Shesha Ehailing Services (Pty) Ltd
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Legal status: </strong>Private Company
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Registration number: </strong>2023/577025/07
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Description of business: </strong>Software Provider
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Platform address: </strong>
                                    <a
                                        href="https://www.shesharide.co.za/"
                                        style={{ color: "#0563c1", textDecoration: "underline" }}
                                    >
                                        https://www.shesharide.co.za/
                                    </a>{" "}
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Email address: </strong>
                                    <a
                                        href="mailto:info@shesharide.co.za"
                                        style={{ color: "#0563c1", textDecoration: "underline" }}
                                    >
                                        info@shesharide.co.za
                                    </a>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Registered address: </strong>
                                    <span style={{ color: "#000000" }}>
                                        Block D, Infinity Business Park, 4 Pieter Wenning Rd,{" "}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ul>
            <p style={{ marginLeft: 206, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <span style={{ color: "#000000" }}>Fourways, Sandton, 2191</span>
                        </span>
                    </span>
                </span>
            </p>
            <ul>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>Postal address: </strong>
                                    <span style={{ color: "#000000" }}>
                                        Block D, Infinity Business Park, 4 Pieter Wenning Rd,{" "}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ul>
            <p style={{ marginLeft: 206, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <span style={{ color: "#000000" }}>Fourways, Sandton, 2191</span>
                        </span>
                    </span>
                </span>
            </p>
            <ol start={18}>
                <li>
                    <p style={{ textAlign: "left" }}>
                        <span style={{ fontSize: 18 }}>
                            <span style={{ color: "#000000" }}>
                                <span >
                                    <strong>General </strong>
                                </span>
                            </span>
                        </span>
                    </p>
                </li>
            </ol>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <a name="_Ref462747447" />{" "}
                        <span >
                            <u>Relationship Between the Parties</u>: The relationship of the
                            parties, <em>inter se</em>, shall be governed by these Terms and
                            nothing contained herein shall be deemed to constitute a partnership,
                            joint venture, employer/employee agreement, agency agreement, or the
                            like between them. No party shall by the reasons of the actions of the
                            other party incur any personal liability as co-partner to any third
                            party. Shesha only provides software as a service,<strong> </strong>
                            any formal engagement between users facilitated by the Platform is
                            between them privately, and for which Shesha holds no responsibility.
                            <strong> </strong>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>
                                <span style={{ backgroundColor: "#ffffff" }}>Force Majeure</span>
                            </u>
                            <span style={{ backgroundColor: "#ffffff" }}>
                                : If either party is prevented, whether in whole or in part, or
                                delayed from performing any of its duties, functions or obligations
                                under these Terms, whether timeously or at all, due to an event out
                                of their control (which for the purposes hereof shall mean war,
                                political riots, civil commotions, electrical load-shedding or
                                surges, legal prohibitions or restrictions, epidemics, pandemics,
                                governmental lockdowns, fire, floods or other similar natural
                                disasters), then such failure shall not constitute a breach under
                                these Terms, and the obligation to perform shall be suspended to the
                                extent and during the continuance of such prevention provided that
                                the parties shall use their reasonable commercial endeavours to
                                minimise any delay occasioned
                            </span>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <a name="_Ref143513800" />
                        <a name="_Hlk128060513" />{" "}
                        <span >
                            <u>
                                <span style={{ backgroundColor: "#ffffff" }}>
                                    Change Without Notice
                                </span>
                            </u>
                            <span style={{ backgroundColor: "#ffffff" }}>
                                : The Platform and these Terms are subject to change without notice.
                                These Terms are updated or amended from time to time and will be
                                effective once we upload the amended Terms to the Platform. Your
                                continued access or use of the Platform constitutes your acceptance
                                to be bound by these Terms, as amended. It is your responsibility to
                                read these Terms periodically to ensure you are aware of any
                                changes.
                            </span>
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>No Indulgence</u>: No indulgence, leniency or extension of time
                            granted by Shesha shall constitute a waiver of any of Shesha’ rights
                            under these Terms and, accordingly, Shesha shall not be precluded as a
                            consequence of having granted such indulgence, from exercising any
                            rights against the client which may have arisen in the past or which
                            might arise in the future.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Importation of Words</u>: Words importing the singular will include
                            the plural and <em>vice versa</em>. Words importing one gender will
                            include the other genders, and words importing persons will include
                            partnerships, trusts, and bodies corporate, and <em>vice versa</em>.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Headings as Reference</u>: The headings to the paragraphs in these
                            Terms are inserted for reference purposes only and will not affect the
                            interpretation of any of the provisions to which they relate.{" "}
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Governing Law</u>: Your access and/or use of the Platform and/or
                            the Services, any downloaded material from it and the operation of
                            these Terms (including any transaction concluded pursuant thereto)
                            shall be governed by and construed in accordance with the laws of the
                            Republic of South Africa.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <a name="z337ya" />{" "}
                        <span >
                            <u>Failure to Pay</u>: In the event of a client failing to pay any
                            amount timeously or breaching these Terms, the client shall be liable
                            for all legal costs (on the scale as between attorney and client)
                            (including collection commission) which may be incurred by Shesha in
                            relation to the payment failure or breach.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <a name="_Ref462747429" />{" "}
                        <span >
                            <u>Severability</u>: Each sentence, paragraph, term, clause and
                            provision of these Terms and any portion thereof shall be considered
                            severable and if for any reason, any such sentence, paragraph, term,
                            clause or provision is held to be invalid, contrary to, or in conflict
                            with any applicable present or future law or regulation or in terms of
                            a final, binding judgment issued by any court, it shall to that extent
                            be deemed not to form part hereof and shall not impair the operation
                            of, or have any effect upon such other sentence, paragraph, term,
                            clause or provision hereof as may otherwise remain valid or
                            intelligible, which shall continue to be given full force and effect
                            and bind the parties hereto.
                        </span>
                    </span>
                </span>
            </p>
            <p style={{ marginLeft: 38, textAlign: "left" }}>
                <span style={{ fontSize: 16 }}>
                    <span style={{ color: "#000000" }}>
                        <span >
                            <u>Prohibited Provision</u>: No term or condition of these Terms is
                            intended to breach any peremptory provisions of any consumer
                            protection legislation and any regulations thereto ("
                            <strong>Prohibited Provision</strong>"). Any breach of any such
                            Prohibited Provision shall be governed by the provisions of
                            severability above.{" "}
                        </span>
                    </span>
                </span>
            </p>
        </div>

    )
}

export default Terms