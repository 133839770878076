import React from 'react'
import { NavLink } from 'react-router-dom'
function DeleteAccount() {
    return (
        <div className='flex justify-center items-center my-10 min-h-[50vh]'>
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        "\n    .container {\n      max-width: 600px;\n      margin: auto;\n    }\n    .header {\n      text-align: center;\n      margin-bottom: 30px;\n    }\n    .instructions {\n      margin-bottom: 20px;\n    }\n    .email-link {\n      font-weight: bold;\n      color: blue;\n    }\n    .privacy-policy {\n      margin-top: 40px;\n      border-top: 1px solid #ccc;\n      padding-top: 20px;\n    }\n    .privacy-link {\n      color: blue;\n      text-decoration: underline;\n    }\n  "
                }}

            ></style>
            <div className="px-10">
                <div className="header px-5 lg:px-10">
                    <h1>Shesha Ehailing - Request Account Deletion</h1>
                    <p>
                        If you wish to delete your account, please follow the instructions
                        below:
                    </p>
                </div>
                <div className="instructions">
                    <p>
                        To initiate the account deletion process, send an email to{" "}
                        <a href="mailto:mailto:contact@shesharide.co.za" className="email-link">
                            contact@shesharide.co.za
                        </a>{" "}
                        with the subject line: "Account Deletion Request."
                    </p>
                    <p>
                        Once we receive your email, our team will process your request and
                        delete your account.
                    </p>
                    <p>
                        If you have any questions or need further assistance, feel free to
                        contact us at{" "}
                        <a href="mailto:mailto:contact@shesharide.co.za" className="email-link">
                            contact@shesharide.co.za
                        </a>
                        .
                    </p>
                </div>
                <div className="privacy-policy">
                    <p>
                        For more information about how we handle your data and our privacy
                        practices, please review our{" "}
                        <NavLink
                            to="/Privacy"
                            className="privacy-link"
                        >
                            Privacy Policy
                        </NavLink>
                        .
                    </p>
                </div>
            </div>
        </div>
    )
}

export default DeleteAccount