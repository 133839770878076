import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import ResI from '../assets/Add Stop.png';
import app from '../assets/App Security.png';
import car from '../assets/Car Safety.png';
import emp from '../assets/Emergency Line.png';
import Fair from '../assets/Fixed Prices.png';
import carsec from '../assets/Identity.png';
import Trans from '../assets/S-Wallet.png';
import Safe from '../assets/Safety.png';
import share from '../assets/Share ride.png';
import HomeTop from '../assets/WhyChooseUs.png';
import Business from '../assets/businessman.png';
import srtP from '../assets/cusStripe.png';
import DriverR from '../assets/empR.png';
import rate from '../assets/interest-rate.png';
import sec from '../assets/security.png';








function ForCustomer() {
    const card = [
        {
            img: Fair,
            head: 'FIXED PRICES',
            p1: "Shesha's prices are fixed and unaffected by weather, season, or time. All of our prices are based on the distance and time travelled. We choose this option to provide our customers with peace of mind when arranging their trips.",
            p2: "This enables customers to plan and budget their trips with greater efficiency."

        }, {
            img: ResI,
            head: 'ADD STOP',
            p1: " ",
            p2: "Don't worry if you need to pick up or drop off something along the road, or if you need to pick up or drop off someone. Shesha App allows you to add stops on your trip without being charged extra, as long as the stop is less than a minute and in the same direction."

        },
        {
            img: Trans,
            head: 'S-WALLET',
            p1: "Shesha only accepts S-wallet payments from customers. This eliminates the need for cash and credit card payments. The wallet allows the user to top up their wallet with any amount they choose, which means that the customer can top up their wallet for the entire month and have peace of mind.",
            p2: "Customers can top up their wallet by linking their card on the app and transferring money to their wallet, or by depositing money into their wallet from any bank ATM using their unique wallet number. There is no need to book on someone else's behalf; you can transfer funds from your wallet to another individual."

        },
        {
            img: Safe,
            head: 'QUALITY AND PROFESSIONAL SERVICE',
            p1: "Fair pricing is at our core. With our fixed prices, riders no longer need to anticipate variable charges",

        }
    ]
    const bottomCard = [
        {
            img: rate,
            head: 'RATE US',
            p1: "If you believe we are not living up to our standards, principles, and values, we encourage you to rate our service at the end of each trip, regardless of whether it was good or bad. This allows us to improve where we fall short and ensure that you are satisfied.",

        },
        {
            img: share,
            head: 'SHARE RIDE',
            p1: "Before entering the car, you can share your ride with one of your contacts. Please make sure that the person with whom you will be sharing your trip is aware of the arrangements and that they have data to track your movements.",

        },
        {
            img: car,
            head: 'CAR SAFETY',
            p1: "All of our vehicles are checked for roadworthiness before they are authorized to operate, and they are tested annually to ensure that they remain in good condition. If an unforeseen incident occurs during your trip, such as a flat tire, the driver will know what to do, you should relax or phone the customer support line, and one of our customer service agents will speak with you. If another car is required, we will dispatch one.",

        }, {
            img: emp,
            head: 'EMERGENCY LINE',
            p1: "We have a help button on the app; if you are in the car and feel like your life is in danger, press the Help button; The help button gives you options of danger or emergency you require assistance with; Once you have selected the relevant option, you click submit and our emergency staff will contact you immediately while performing driver checks in the background. We will always do everything possible to keep you safe.",

        }, {
            img: app,
            head: 'APP SECURITY',
            p1: "We have introduced security elements such as fingerprint and identity number verification for drivers to ensure that the drivers are who they claim they are. We've also introduced an emergency button function, which allows the rider to alert the emergency assistance desk about the emergency or danger the rider is in, as well as a helpline that the rider can call to report any emergency.",

        }, {
            img: carsec,
            head: 'CAR AND DRIVER IDENTITY',
            p1: "All of our drivers have photos on their profiles. After you request the ride and the driver arrives at your location, you must check that the driver is the one in the photo. You must also confirm the car details, such as the make, colour, and registration number. If any or all of the descriptions do not match, you have no right to enter the car and must immediately notify Shesha.",

        }
    ]

    return (
        <div>
            <div className="lg:min-h-[90vh] min-h-[50vh] bg-cover  bg-no-repeat pt-5 " style={{ backgroundImage: `url(${HomeTop})`, boxShadow: 'rgba(0, 0, 0, 0.6) 500px 5000px inset', }} >
                <Slide direction='down' triggerOnce>
                    <div className="text-center pt-10 tracking-wider  font-extrabold">
                        <Fade delay={500} triggerOnce duration={200} cascade className='text-3xl md:text-6xl lg:text-7xl font-bold text-white '>
                            Why Choose

                        </Fade>
                        <Fade delay={1500} triggerOnce duration={500} cascade className='text-3xl ms-2  md:text-6xl lg:text-7xl font-bold text-[#f62f02] '>
                            Us

                        </Fade>
                    </div>
                </Slide>
            </div>
            <div className="lg:min-h-[90vh] min-h-[50vh] bg-cover  bg-no-repeat pt-5 flex justify-center items-center " style={{ backgroundImage: `url(${Business})`, boxShadow: 'rgba(0, 0, 0, 0.2) 500px 5000px inset', }} >
                <div className="grid md:grid-cols-2 lg:grid-cols-2 grid-cols-1 place-items-start py-16 px-5 lg:px-0 place-content-start gap-5 lg:gap-16 ">
                    {card.map((e, i) => {
                        return (
                            <Fade delay={i * 150} direction='up' triggerOnce>
                                <div className="card relative max-w-[35rem] border-2  lg:p-5 md:p-3 p-2 my-5 lg:my-0 min-h-[200px] lg:min-h-[auto]   rounded-lg bg-white shadow-2xl">
                                    <div className="absolute -top-8 lg:-top-14 -left-4">
                                        <img src={e.img} alt="" className='p-2 lg:p-4 text-center h-[50px] md:h-[60px] lg:h-[80px] bg-[#f62f02] rounded-full object-fill ' />
                                    </div>
                                    <div className="text-left pt-8 space-y-4 p-2">
                                        <strong className='pb-2 font-extrabold text-sm lg:text-lg'>{e.head}</strong>
                                        <p className='font-medium text-xs md:text-sm lg:text-lg'>{e.p1}</p>
                                        <p className='font-medium text-xs md:text-sm lg:text-lg '>{e.p2}</p>


                                    </div>

                                </div>
                            </Fade>
                        )
                    })}


                </div>
            </div>
            {/* emeergency ride */}
            <section className='grid grid-cols-1  md:grid-cols-2 lg:grid-cols-2  items-start lg:items-start '  >

                <Slide direction='left' triggerOnce style={{ background: `url(${srtP}) `, backgroundSize: 'contain', backgroundPositionY: '-30%', backgroundPositionX: '-5pc', backgroundRepeat: 'no-repeat' }}>
                    <div className="">
                        <img src={DriverR} alt="" className='' />
                    </div>
                </Slide>
                <Slide direction='right' className='mt-20' triggerOnce>
                    <div className="p-5">
                        <h3 className='text-[#f62f02] text-2xl md:text-3xl lg:text-4xl font-extrabold  lg:ms-0'>

                            EMERGENCY    <span className='text-black'> RIDE</span>
                        </h3>
                        <div className="text-lg lg:text-2xl p-2 py-2 md:pb-0 lg:py-5 space-y-8">

                            <p className='leading-8 lg:leading-10'>If you need to get someplace quickly but don't have any money in your wallet, don't panic; Shesha has you covered. Customers using the Emergency Ride service can request journeys of less than
                                5 kilometres and pay later.</p>
                            <h3 className='text-black text-2xl md:text-3xl lg:text-4xl font-extrabold py-1  lg:py-5 '>

                                How    <span className='text-[#f62f02]'>It </span> Works?
                            </h3>
                            <p className='leading-8 lg:leading-10'>You select Emergency Ride on the app and enter your pickup and drop-off locations (the Shesha app should locate your pickup location automatically). If the distance is within the emergency ride limit, you will select your preferred vehicle and complete the order. The driver will then accept your ride and you will be picked up and dropped off at your location.</p>
                            <p className='leading-8 lg:leading-10'>If the distance exceeds the emergency ride distance limit, a warning stating "Your destination exceeds the emergency ride distance limit" will appear.</p>
                        </div>


                    </div>
                </Slide>


            </section>
            {/* final section */}
            <div className="lg:min-h-[90vh] min-h-[50vh] bg-cover  bg-no-repeat pt-5 flex-col flex justify-center items-center " style={{ backgroundImage: `url(${sec})`, boxShadow: 'rgba(0, 0, 0, 0.2) 500px 5000px inset', }} >
                <h3 className='text-white text-2xl md:text-3xl lg:text-4xl font-extrabold py-1  lg:py-5  uppercase'>
                    <span className='text-[#f62f02]'> Safety </span> & security
                </h3>
                <div className="grid md:grid-cols-2 lg:grid-cols-2 grid-cols-1 place-items-start py-16 px-5 lg:px-0 place-content-start gap-5 lg:gap-16 ">
                    {bottomCard.map((e, i) => {
                        return (
                            <Fade delay={i * 150} direction='up' triggerOnce>
                                <div className="card relative max-w-[35rem] border-2  lg:p-5 md:p-3 p-2 my-5 lg:my-0 min-h-[200px] lg:min-h-[auto]   rounded-lg bg-white shadow-2xl">
                                    <div className="absolute -top-8 lg:-top-14 -left-4">
                                        <img src={e.img} alt="" className='p-2 lg:p-4 text-center h-[50px] md:h-[60px] lg:h-[80px] bg-[#f62f02] rounded-full object-fill ' />
                                    </div>
                                    <div className="text-left pt-8 space-y-4 p-2">
                                        <strong className='pb-2 font-extrabold text-sm lg:text-lg'>{e.head}</strong>
                                        <p className='font-medium text-xs md:text-sm lg:text-lg'>{e.p1}</p>
                                        <p className='font-medium text-xs md:text-sm lg:text-lg '>{e.p2}</p>


                                    </div>

                                </div>
                            </Fade>
                        )
                    })}


                </div>
            </div>
        </div>
    )
}

export default ForCustomer