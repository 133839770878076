import React, { useEffect, useRef, useState } from 'react';
import { MdArrowDropDown, MdArrowRight } from "react-icons/md";
import '../App.css'; // Import the CSS file with transition effects
function Accordion({ q, answer, className = '' }) {
    const [showText, setShowText] = useState(false);
    const headerRef = useRef(null);
    const toggleMenu = () => {
        setShowText(!showText);
    };
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!headerRef.current.contains(event.target)) {
                setShowText(false);
            }
        };

        if (showText) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showText]);
    return (
        <div ref={headerRef} className={`w-full shadow-2xl bg-white flex justify-start flex-col font-bold text-2xl border-2 p-2 text-left cursor-pointer selection:not-sr-only border-[#2a2b2d] max-w-[720px] ${className}`} onClick={() => setShowText(!showText)}>
            <p className='text-left text-sm md:text-md lg:text-lg flex items-center  ' >
                {
                    showText ? <MdArrowDropDown className='text-4xl text-[#f62f02]' /> : <MdArrowRight className='text-4xl text-[#f62f02]' />
                }  {q}
            </p>
            {
                showText && (
                    <div className={`font-normal transition-height text-sm md:text-md py-5   ${showText ? 'open' : 'close'} ${className != '' ? 'lg:text-[20px]' : 'lg:text-lg'}`}>
                        {showText && answer}
                    </div>
                )
            }

        </div>
    );
}

export default Accordion;
